.job-desc {
    padding-bottom: 30px;
}

.job-desc-title {
    width: 60%;
    margin: auto;
}

.job-desc-p {
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -.2px;
    font-weight: 400;
    color: #414141;
    padding-top: 48px;
}

.job-desc-h3 {
    color: #414141;
    font-family: "goudy-old-style", Georgia, Serif, serif;
    font-weight: 400;
    font-size: 50px;
    line-height: 32px;
    letter-spacing: -.2px;
}

.job-desc-p1 {
    font-family: "goudy-old-style", Georgia, Serif;
    font-weight: 300 !important;
    font-size: 22px;

}
p{
    font-weight: 300 !important;
    color: #fff;
}

/* p:nth-child(2) {
padding-left:25px;
} */


.btn-view {
    background-color: transparent;
    border: 1px solid #9b9b9b;
    color: rgba(65, 65, 65, .85);
    cursor: pointer;
    font-family: akzidenz-grotesk-pro, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    line-height: 15px;
    margin-top: 15px;
    margin-bottom: 40px;
    width: 100px;
}

.btn-view:hover {
    color: #f5f5f5;
    background-color: #222;

}

@media (min-width: 320px) and (max-width: 768px) {
    .job-desc-title {
        width: 90%;
        margin: auto;
    }
    .job-desc-h3 {
        line-height: 45px;
    }
}


@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    .job-desc-h3  {
        line-height: 40px;
    }
}