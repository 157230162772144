.services {
  margin-top: 40px;
}



.btn-view {

  width: 147px;
  height: 33px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 1px solid #9B9B9B;
  color: #fff;
  cursor: pointer;
  background-color: black;
  font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, sans-serif;
}

.btn-view:hover {
  color: #f5f5f5;
  background-color: #000;
}


.service-part {
  width: 100%;
  padding: 5% 3% 5% 3.5%;
  margin: 0px;
  padding-right: 20px;
}

.service-img {
  width: 100%;
}

.servicetitle {

  font-size: 15px;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
}

.servicetitle1 {
  margin-top: -0%;
  font-family: "goudy-old-style", Georgia, Serif, serif;
  line-height: 18px;
}

.linkhref a {
  color: black;
}

.services-img {
  padding: 10px;
}

.servicetitle2 {
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 23px;
  padding-top: 10px;
  color: white;
}


.Serviceimages {
  padding-right: 1.5%;
}


.img-hover-zoom {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

#imghover {
  transition: all 1.5s ease;
}

#imghover:hover {
  transform: scale(1.05) !important;
}

.serviceheading {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1.4px;
  margin-left: -3px;
  margin-bottom: -2px;
  color: #fff !important;
}

.service-title {
  max-width: 700px;
  margin-left: 4%;
  padding-top: 45px;
  padding-bottom: 0%;
}

.serviceheadingp {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 9px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
}

@media only screen and (max-width: 768px) {
  .btn-view {
    width: 158px;
    height: 33px;
  }

  .service-part {
    margin-bottom: 20px;
  }

  .serviceheading {
    margin-left: -1%;
  }

  .service-title {
    margin-left: 3.5%;
    padding-top: 36px;
  }

  .serviceheadingp {
    margin-bottom: 30px;
  }
}




@media (min-width: 770px) and (max-width: 1080px) {
  .serviceheading {
    margin-left: -4px;
  }

  .service-title {
    padding-top: 37px;
  }

  .service-part {
    padding-top: 5.8%;
    padding-bottom: 5.8%;
  }
}