.lookFor_container{
    margin-top: 40px !important;
}

.Lookfor {
    margin: 6% 0% 0% 0%;
}

.Lookfor-title {
    width: 40%;
    margin: -3% 0% 6% 13%;
}

.Lookfor-title p {
    color: #fff;
    font-family: akzidenz-grotesk-pro, Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 30px;
}

.Lookfor-H {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 6px;
    margin: 0;
    margin-bottom: 50px;
}

.Lookfor-items-p {
    width: 50%;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 22px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 15px;
}

.Lookfor-items-p1 {
    width: 50%;
    margin: 0% 0% 0% 18%;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    line-height: 26px;
    padding: 2% 0% 0% 7%;
}

.LookforButton {
    background-color: transparent !important;
    border: 1px solid #9b9b9b !important;
    color: #fff !important;
    cursor: pointer;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    line-height: 15px;
    margin-top: 15px;
    width: 184px;
    border-radius: 0 !important;
    margin: 0% 0% 5.7% 25% !important;
    text-transform: capitalize !important;
}


.LookforButton:hover {
    background-color: #121212 !important;
    color: #f5f5f5 !important;
}

.adven-h6 {
    color: #fff;
    font-family: akzidenz-grotesk-pro, Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 18px;
    margin: 3% 0% -1% 25%;
}

.Lookfor-items-img {
    width: 100%;
    object-fit: cover !important;
}

.Lookfor-items-p2 {
    width: 50%;
    margin: 0% 0% 0% 18%;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    line-height: 26px;
    padding: 2% 0% 0% 7%;
}

.Lookfor-items img {
    object-fit: cover;
}

.Lookfor-items img {
    width: 100% !important;
}

@media (min-width: 320px) and (max-width: 768px) {
    .Lookfor-H {
        line-height: 23px;
    }

    .Lookfor-title {
        width: 80%;
        margin: 10% 0% 16% 13%;
    }

    .Lookfor-items img {
        width: 100% !important;
        display: block;
        margin: auto;
    }

    .Lookfor-items-p {
        width: 85%;
        margin: 6% 0% 1% 6%;
        padding: 2% 0% 0% 5%;
    }

    .Lookfor-items-p1 {
        width: 88%;
        margin: 0% 0% 0% 6%;
        padding: 2% 0% 0% 5%;
    }

    .Lookfor-items-p2 {
        width: 88%;
        margin: 0% 0% 0% 6%;
        padding: 2% 0% 0% 5%;
    }

    .adven-h6 {
        margin: 3% 0% -1% 11%
    }

    .LookforButton {
        margin: 12px 12px 75px 45px !important;
    }

}


@media (min-width: 770px) and (max-width: 1080px) {
    .Lookfor-title {

        margin: -1% 0% 9.5% 13%;
    }

    .LookforButton{
        margin-bottom: 75px !important;
    }
}