/* @import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Open+Sans:wght@300&display=swap'); */
* {
  box-sizing: border-box;
  /* font-family: "akzidenz-grotesk-pro"; */
}

@font-face {
  font-family: 'TT Hoves';
  src: url('../src/components/styles/fonts/TTHoves/TTHoves-Medium.woff2') format('woff2'),
    url('../src/components/styles/fonts/TTHoves/TTHoves-Medium.woff') format('woff');
}

@font-face {
  font-family: "goudy-old-style";
  font-style: normal;
  font-weight: 400;
  src: local("goudy-old-style"),
    url("../src/components/styles/fonts/goudy-old-style/Goudy\ Old\ Style\ Regular.ttf") format("truetype");
}

@font-face {
  font-family: "akzidenz-grotesk-pro";
  font-style: normal;
  font-weight: 400;
  src: local("akzidenz-grotesk-pro"),
    url("../src/components/styles/fonts/Akzidenz\ Grotesk/Akzidenz-grotesk-light.ttf") format("truetype");
}

@font-face {
  font-family: "D-DIN";
  font-style: normal;
  font-weight: 400;
  src: local("D-DIN-Bold"),
    url("../src/components/styles/fonts/Akzidenz\ Grotesk/D-DIN-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("poppinz"),
    url("../src/components/styles/fonts/poppinz/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-regular";
  font-style: normal;
  font-weight: 400;
  src: local("poppinz"),
    url("../src/components/styles/fonts/poppinz/Poppins-Regular.ttf") format("truetype");
}

/* .MuiToolbar-regular{
  display: none !important;
}  */
/* .MuiPaper-elevation  {
  background-color: #000000 !important;
}  */
.MuiDrawer-paperAnchorRight {
  margin-top: 110px;
  width: 100%;
}

.MuiList-padding li {
  border-bottom: 1px solid white !important;
  justify-content: flex-end;
  letter-spacing: -1.6px;
  line-height: 90%;
  padding: 12px 0px 10px;
}

.MuiList-padding {
  width: 90% !important;
  margin: auto !important;
}

/* .main-container .MuiBackdrop-root{
 display: none !important;

} */
.MuiBackdrop-root {
  background-color: transparent !important;
}

body {
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* height: 100vh; */
  /* font-weight: 400 !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 26px !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar-thumb {
  background-color: #000 !important;
  width: 12px !important;
  border-radius: 0 !important;
}

.scrollbar-track-y {
  width: 12px !important;
  background-color: transparent !important;
}

#imghover {
  transition: all 1.5s ease;
}

#imghover:hover {
  transform: scale(1.05) !important;
}

#main-header {
  position: sticky;
  top: 0;
  display: block;
  transition: top 1s;
}

a {
  cursor: pointer;
}

.css-ktslay-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}