

.case-title {
    max-width: 460px;
    margin-left: 20%;
    padding-top: 32px;
    padding-bottom: 3%;
    margin-top: 40px;
    color: white;
  }

.caseheading{
    font-weight: 400;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -1.4px;
    font-family: "goudy-old-style", Georgia, Serif;
  }

  
.case-part {
    width: 100%;
    padding: 5% 2.8% 5% 2.8%;
  }

.case-img {
    width: 100%;
  }

.casetitle{
  color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -.2px;
    font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
    padding-top: 15px;
    padding-bottom: 10px;
  }
.casesubtitle{
    margin-top: -3%;
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;

    color: #121212;
    font-family: "goudy-old-style",Georgia,Serif,serif;
  }

.casesubtitle1{
    margin-top: -5%;
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;
    
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
}


:placeholder-shown{
    font-size: large;
}

.search {
  background-image: url("../../../public/assets/images/casestudies/Search icon.svg");
  background-repeat: no-repeat;
  background-position: 12px 15px;


}

input[type="text"] 
{ border: none }

.inputsearch{
width: 45%;
margin-left: 20%;
padding: 10px 50px 15px;
outline: none;
border-top: none;
border-bottom: 1px solid whitesmoke !important;
border-right: none;
border-left: none;
}

.inputsearch:hover{
  border-bottom: 1px solid black !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.casestudy-div{
 
  margin-left: 1%;
}

.casestudies-img{
 
  padding: 16px;
}

.careerUs-img{
  margin-left: 2%;
}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

#imghover{
  transition: all 1.5s ease;
}
#imghover:hover {
  transform: scale(1.05) !important;
}

.CloseButton {
  position: relative;
  
  top: 10px;
  right: 30px;
  cursor:pointer;
}



@media screen and (min-device-width: 360px) and (max-device-width: 500px){

.case-part{
  padding: 38px 0px 34px 8px;
}
  .case-title {
    width: 100%;
    margin-left: 0;
    padding-top: 38px;
    padding-bottom: 3.3%;
    padding-left: 35px;
  }
  .searchCase{
    margin-left: 15px !important;
    width: 60% !important;
  }
  .no-case-studies-found{
    padding-left: 26% !important;
  }
  .CloseButton {
    position: relative;
  
    cursor: pointer;
  }
  .inputsearch{
    width: 77%;
    margin-left: 16%;
    padding: 12px 52px 15px;
  }
  .aboutUs{
    width: 90%;
  }

  .inpWidth{
    width: 50%;
  }

  .casestudies-img{
 
    padding-right: 12px !important;
  }
}
@media screen and (min-device-width: 501px) and (max-device-width: 768px){
  .case-title {
    width: 100%;
    margin-left: 0;
    padding-top: 70px;
    padding-bottom: 3%;
    padding-left: 10px;
  }
  .inputsearch{
    width: 90%;
    margin-left: 0%;
    margin-left: 10px;
  }
  .aboutUs{
    width: 90%;
    display: block !important;
    margin: 0 auto !important;
  }

  .no-case-studies-found{
    padding-left: 35% !important;
  }
}


@media screen and (min-device-width: 770px) and (max-device-width: 1080px){
  .case-title{
    padding-top: 38px
  }

  .casestudy-div{
    margin-left: 12px;
    margin-top: 10px;
  }
}

@media screen and (min-device-width: 2400px) and (max-device-width: 3000px){
  .casetitle{
 padding-bottom: 30px;
}

}
@media screen and (min-device-width: 3001px) and (max-device-width: 4000px){
  .casetitle{
 padding-bottom: 40px;
}
}

.no-case-studies-found{
padding-left: 40%;
  font-size: 22px  !important;
  color: #121212 ;
}