.certification_container {
    margin-top: 40px !important;
}

.img-hover-zoom {
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}


.certification-title {
    width: 40%;

    margin: auto;
    margin-top: 45px;

}

.certification-h3 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 50px;
    margin: 0;
    padding-bottom: 20px;

}

.certificate_main {
    display: flex;
    gap: 30px;

    justify-content: center;
}

.certification-p {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 23px;
    line-height: 30px;
    padding-top: 25px;
}

.certification-img {

    background-color: #f5f5f5;
    margin-top: 55px;
}

.certification-imgs {

    padding: 16px;
}


.certification-part {
    width: 100%;
    padding: 5% 1.2% 5% 2.4%;
    background-color: #000;
}


.certification-imghover {
    width: 100%;
}

.certification-p1 {
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 28px;
    color: #fff;
    padding-top: 5%;
    padding-left: 2.2%;
    padding-bottom: 0%;
    background-color: #000;
}

.membership-title {
    font-family: 'goudy-old-style', Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 28px;
    color: #121212;
    padding-top: 5%;
    padding-left: 2.2%;
    padding-bottom: 5%;
}



.Membership-img {
    margin-top: 55px;

    padding: 15px 0px 15px 5px;
}

.membership-images {
    display: flex;
    flex-direction: column;
}

.Membership-imgs img {
    width: 580px;
    height: auto;
}


.Membership-imgs1 {
    padding: 16px;
}

.Membership-part {
    width: 100%;
    padding: 5% 2.8% 5% 4.8%;
}

.member {
    display: flex;
    flex-direction: column !important;
    gap: 34px;
}

.Membership-imghover {
    width: 100%;
}

.certificate {
    display: flex;
    gap: 30px;
    justify-content: center;
}

.membership-div {
    width: '90%';
    margin: 'auto';
}

.membership-title {
    font-family: 'SD Georgia Light';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    padding-top: 5%;
    padding-left: 4.8%;
    padding-bottom: 3%;
}


.membership-images {
    margin: auto;
    padding-bottom: 102px;
}

/* 
@media (min-width: 414px) and (max-width: 896px){
    .membership-div{
        width: '90%';
        margin: 'auto';
        margin-left: 50px;
   
     }
     
} */

@media screen and (min-device-width: 360px) and (max-device-width: 500px) {
    .certificate {
        margin-left: 29px !important;
        padding: 0px !important;
    }

    .certification-imgs {
        padding-right: 8px !important;
    }

    .certification-p1 {
        padding-left: 4.9% !important;
    }

    .main-img {
        padding-right: 30px !important;
    }

    .membership-images {
        padding-bottom: 30px !important;
    }

    .membership-title {
        padding-left: 4.9% !important;
    }
}

@media screen and (min-device-width: 501px) and (max-device-width: 768px) {
    .certificate {
        margin-left: 40px !important;
    }


    .certification-p1 {
        padding-left: 4.9% !important;
    }

    .membership-title {
        padding-left: 4.9% !important;
        padding-bottom: 0px !important;
    }
}

/* @media (min-width: 414px) and (max-width: 786px){
    .certificate{
        margin-left: 29px !important;
        padding: 0px !important;
    }

    .certification-part{
        padding: 5% 1.2% 5% 5.1%;
    }

    .membership-title{
        padding-left: 10.2% !important;
    }

    .certification-p1{
        padding-left: 4.9%;
    }

    .main-img{
        width: 332px;
    }
    .membership-images{
        padding-bottom: 78px;
    }
} */


@media only screen and (min-width: 1700px) {
    .Certifications {
        justify-content: center;
    }

    .Membership-imgs img {
        width: auto !important;
    }
}

@media only screen and (max-width: 1320px) {
    .Certifications {
        justify-content: center;
    }

    .certificate {
        padding: 0px 12px 0px 12px;
    }

    .certification-title {
        width: 42%;
    }
}

@media only screen and (max-width: 820px) {
    .certification-title {
        width: 90%;
        margin-top: 40px;
    }

    .certificate_main {
        flex-direction: column;
    }

    .Membership-imgs img {
        width: 90%;
        height: auto;
    }




}

.memberimg {
    width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .Membership-imgs img {
        width: 90%;
    }

    .membership-images {
        padding-bottom: 80px;
    }

    .main-img {
        width: 661px;
    }

    .certification-part {
        padding-right: 0.5%;
    }
}

@media (min-width: 1025) and (max-width: 1288px) {
    .Membership-imgs img {
        width: 80% !important;
    }
}

@media only screen and (max-width: 1025px) {
    .certificate {
        flex-direction: column !important;

        margin-left: 59px;
    }

    .certificate img {
        width: 100%;
    }

    .member {
        flex-direction: column !important;

    }

    .member img {
        width: 100%;
    }

    .membership-title {
        padding-left: 9%;
    }
}