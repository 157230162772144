.strategyToExecution_container {
  margin-top: 40px;
}

.stratHead {
  width: 80% !important;
  padding: 40px;
  display: block;
  margin: auto;
}

.stratergy h3 {
  color: #fff;
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif;
  font-weight: 400;
  font-size: 23px;
  line-height: 22px;
  padding-bottom: 15px;
}

.stratergy p {
  font-size: 19px;
  line-height: 26px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;

}

.stratergy h4 {
  color: #fff;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -.2px;
  font-weight: 400;
}

.stratergy h1 {
  font-size: 50px;
  line-height: 42px;
  letter-spacing: -1.4px;
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif, serif;
  font-weight: 400;
  color: #121212;
}

.stratergys h4 {
  color: #fff;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -.2px;
  font-weight: 400;
}

.stratergys h1 {
  font-size: 50px;
  line-height: 20px;
  letter-spacing: -1.4px;
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif;
  font-weight: 400;
  color: #fff;
  padding-bottom: 12px;
}

.stratergys p {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
}

.solutionp {
  font-size: 24px;
  line-height: 12px;
  color: #414141;
  font-family: "goudy-old-style", Georgia, Serif, sans-serif;
}

.stretVedio {
  display: block;
  width: 100%;
  margin: auto;
  padding-bottom: 64px;
}

.Insights {
  max-width: 700px !important;
  display: block;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 96px;
}

.media_flow {
  height: 380px;
  width: 85%;
}

.sustainMainVedio {
  justify-content: center;
  gap: 30px;
}

.twoImages {
  padding-bottom: 60px;
}

.sustain_img_experient {
  height: 350px;
  width: 100%;
  padding-bottom: 15px;
}

.media_flow {
  height: 650px;
  width: 85%;
}

.mediaFirst {
  width: 100%;
  height: 730px;
}

.sustainMainVedio {
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.leadingFront {
  width: 50%;
  display: block;
  margin: auto;
  padding-top: 30px;

}


.sustain_img_Ice {
  height: 670px;
  width: 80%;
  padding-bottom: 50px;
  display: block;
  margin: auto;
}

.VedioPattern {
  display: flex;
  flex-direction: column !important;
  gap: 30px;
  padding-left: 0px !important;
}

.playerExperience {
  max-width: 700px !important;
  display: block;
  margin: auto;
  padding-bottom: 96px;
}

.btn-views {

  margin-top: 15px;
  width: 130px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 1px solid #9B9B9B;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, sans-serif;
}

.btn-views:hover {
  color: #f5f5f5;
  background-color: #000;
}

.InsightsLast {
  font-size: 16px;
  line-height: 26px;
  max-width: 700px !important;
  display: block;
  margin: auto;
  padding-bottom: 96px;
}

.career-block2 {
  background-color: #f5f5f5;
  padding: 100px;
}

.career-content1 {
  height: 450px !important;
  cursor: pointer;
  height: 100% !important;
}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .2s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.1);
}

.img-res {

  height: 100%;
  width: 88%;
  padding: 1px;

}



@media screen and (min-device-width: 355px) and (max-device-width: 767px) {
  .career-block2 {
    background-color: #f5f5f5;
    padding: 20px;
  }

  .stratergys h1 {
    line-height: 43px;
    margin-top: -2px;
  }

  .imgText {
    flex-direction: column !important;
    gap: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

  }

  .stratergyblock {
    margin-top: 24px;
    margin-bottom: -12px;
  }

  .stretVedio {
    width: 100%;
    padding-bottom: 20px;
  }

  .stratergy {
    padding: 30px;
  }

  .stratergy h3 {
    color: #121212;
    margin: 0;
    font-family: "goudy-old-style", Georgia, Serif, serif;
    font-weight: 400;
    font-size: 23px;
    line-height: 22px;
    padding-bottom: 4px;
  }

  .btn-views {
    margin: 30px;
  }

  .Insights {
    padding-bottom: 0px;
  }

  .media_flow {
    height: 600px;
    width: 80%;
  }

  .twoImages {
    padding-bottom: 0px;
  }

  .stratHead {
    padding-bottom: 33px;
  }



  .strategy-btn {
    padding: 0px 30px 85px 0px !important;
  }

  .sustainMainVedio {
    padding-bottom: 20px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  .career-block2 {
    padding: 50px;
  }

  .stretVedio {
    width: 95%;
  }
}


.twoImgTextGridMain {
  width: 90%;
  margin: auto;
  margin-top: -28px;
}

.imgText {

  display: flex;
  gap: 80px;
  width: fit-content;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 55px;
}