.footer {
  padding: 4% 42% 2% 5% !important;
  color: #919191;
  height: 380px !important;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
}

.footer-ul {
  list-style: none;
  padding-inline-start: unset;
  color: #919191;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;

}

.footer-h {
  font-size: 16px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #fff;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;

}

.socialIcons {
  display: flex;
}

.footer-height {
  height: max-content !important;
  padding-left: 75px !important;
}

.footer-block {
  margin-top: -3% !important;
}

.footer-ul .link-to-go {
  font-size: 13px;
  line-height: 2;
  color: #919191;
  font-weight: 400;
  letter-spacing: -0.2px;
  cursor: pointer;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  display: block;
  text-decoration: none;
}

.footer-ul li {
  font-size: 16px;
  line-height: 27px;
  color: #919191;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: -0.2px;
  cursor: pointer;
  font-family: "akzidenz-grotesk-pro", sans-serif;
  display: block;
  text-decoration: none;
}

.footer-ul li:hover {
  color: #fff;
}

.footer-ul .link-to-go:hover {
  color: #fff;
}

.social-networks {
  list-style-type: none;
  padding: 0px;
  padding-top: 3%;
}

.social-networks li {
  padding-right: 2% !important;
  display: inline;
}

.social-icons {
  width: 26px !important;
}

.link1 {
  text-decoration: none;
  color: #919191 !important;

}

.footer-people {
  margin-left: -34px;
}

.link1:hover {
  cursor: pointer;
  text-decoration: none;
  color: #fff !important;
}

.footer-main {
  background-color: #222;
}

@media screen and (min-device-width: 350px) and (max-device-width: 768px) {
  .footer-height {
    height: max-content !important;
    padding-left: 35px !important;
  }
}

@media screen and (min-device-width: 600px) and (max-device-width: 900px) {
  .social-icons {
    width: 26px !important;
  }

  .footer-pad {
    padding-left: 32px !important;
  }
}

@media screen and (min-device-width: 900px) and (max-device-width: 1024px) {
  .social-icons {
    width: 17px !important;
  }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1210px) {
  .social-icons {
    width: 21px !important;
  }
}

@media only screen and (min-device-width: 901px) and (max-device-width: 3840px) {
  .left-element {
    padding-left: 0px !important;
  }
}


.copyRight {
  font-size: 16px;
  line-height: 13px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #fff;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  color: #919191;
  padding-top: 28px;
}