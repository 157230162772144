.sustainability{
  margin-top: 40px !important;
}

.sustain{
    margin: 3.5% 0% 0% 0%;
}

.sustain-title{
    width: 50%;
    margin: 0% 0% 0% 15%;
}

.sustain-p{
  font-size: 24px;
  line-height: 12px;
  
  color: #fff;
  font-family:  "goudy-old-style", Georgia, Serif;
  padding-bottom: 40px;
}

.sustainP{
  color: #414141;
  font-family: 'akzidenz-grotesk-pro',Helvetica,Arial,Sans-Serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.2px;
  line-height: 30px;
margin: 0% 0% 0% 10%;
}

.sustainHeading{
    margin: 0% 0% 3% 0%;
    color: #fff;
    font-family:  "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 50px;
  
    padding-bottom: 20px;

}

.sustain-earth-img{
    width: 100%;
    object-fit: cover;
}

.sustain-items{
    margin: 3% 0% 0% 21% !important;
    width: 58% !important;
    display: block;
}

.sustain-items-p{
  color: #fff;
  font-family:  "goudy-old-style", Georgia, Serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  padding-bottom: 15px;
    margin: 5% 0% 2% 0%;

}

.sustainP {
  color: #fff;
  font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.2px;
  line-height: 18px;
  margin: 0;
}

.sustainH {
  color: #121212;
  font-family: goudy-old-style,Georgia,Serif,serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  padding-bottom: 15px;
}

.sustainList li{
  color: #414141;
  font-family: goudy-old-style,Georgia,Serif;
  font-size: 19px;
  line-height: 26px;
}

.sustain-para1{
  color: #fff;
  font-family:  "goudy-old-style", Georgia, Serif;
  font-size: 19px;
  line-height: 26px;
    
}
.career-content1 {
    height: 450px !important;
    cursor: pointer;
    height: 100% !important;
  }

  .img-hover-zoom{
    overflow: hidden;
  }
  .img-hover-zoom img {
    transition: transform .2s ease;
  }
  
  .img-hover-zoom:hover img {
    transform: scale(1.1);
  }

  .img-res {
   
    height: 100%;
    width: 88%; 
    padding: 1px;
  }

  .img-sub-head {
    font-weight: 400;
    color: #121212;
    font-size: 22px;
    line-height: 27px;
    margin: 1% 0% 20% 0%;
    font-family: "goudy-old-style",Georgia,Serif,serif;
  }

  .sustainDown {
    padding: 70px;
    background-color: #f5f5f5;
  }

  .img-sub-p{
    color: #414141!important;
    font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
    font-size: 16px!important;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 18px;
    margin: 0;
    padding-bottom: 0;
    padding-top: 8px;
    text-align: left;
    word-break: break-all;
  }

  .img-sub-h3{
    color: #121212!important;
    font-family: goudy-old-style,Georgia,Serif,serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    padding-top: 0;
  }

  .sustain_img_experient{
    height: 350px;
    width: 100%;
    padding-bottom: 15px;
  }

  .media_flow{
    height: 350px;
    width: 100%;
    padding-top: 15px;
  }
  .mediaFirst{
    height: 700px;
  }

  .sustainMainVedio{
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sustainimg{
    margin-bottom: 18px;
  }

  .leadingFront {
    width: 50%;
    display: block;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }


  .sustain_img_Ice {
    height: 670px;
    width: 80%;
    padding-bottom: 80px;
    display: block;
    margin: auto;
    margin-bottom: -18px;
  }

  .GreenTeams{
    width: 30%;
    padding-left: 80px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .firstImg {
    display: block;
    width: 90%;
    margin: auto;
    /* padding-left: 8px; */
  }


@media (min-width: 320px) and (max-width: 768px) {
  .sustain-title{
    width: 90%;
    margin: 12% 0% 0% 5%;
}

.sustainMainVedio{
  padding-right: 3px;
}

.sustainHeading{
  margin-top: 12px;
}

.sustain_img_Ice{
  
  margin: auto;
  height: 300px;
    width: 80%;
    /* padding-bottom: 68px; */
    margin-bottom: -40px;
    padding-right: 4px;
    display: block;
}

.sustain-items{
  margin: 5% 0% 0% 5% !important;
  width: 90% !important;
}

.sustain_img_experient{
  padding-left: 30px;
  padding-right: 30px;
  height: auto;
}
.leadingFront {
  width: 86%;
  padding: 18px;
}

.mediaFirst{
 
  width: 100%;
  padding-left: 32px;
  padding-right: 30px;
  height: auto;
}


.media_flow{
  padding-left: 30px;
  padding-right: 30px;
  height: auto;
}

.GreenTeams{
  width: 90%;
  padding-left: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.sustainDown {
  padding: 33px;
  background-color: #f5f5f5;
}
}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px){
  .sustain_img_Ice{
    height: 400px;
    /* width: 80%;
    padding-bottom: 80px;
    display: block;
    margin: auto; */
  }
  .sustain-title{
    width: 72%;
    margin-top: 55px;
  }
}