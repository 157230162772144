.expertise-title {
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif, serif;
  font-weight: 400;
  padding: 3% 5% 4% 5%;
  margin-left: 8%;
  margin-right: 31%;
  margin-top: 40px;
}

.linkp {
  color: white;
}

.image-element {
  position: relative;
  width: 0;
  height: 670px;
  margin-left: 50%;
  width: 1441px !important;
}

.expertise-content {
  font-size: 50px;
  font-style: normal;
  line-height: 50px;
  letter-spacing: -1.4px;
  color: #fff;
  font-weight: 400;
  font-family: "goudy-old-style", Georgia, Serif;
}

.expertise-images {
  width: 100%;
  transition: none !important;
  height: 100%;
  object-fit: cover;

}

.expertise-images:hover {
  transform: none !important;
}

.expertise-sec {
  position: absolute;
  top: 171px;
  color: #fff;
  width: 500px;
  margin-left: 10%;
}

.expertise-sec-h {
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 41px !important;
  line-height: 56px;
  letter-spacing: -0.03em;
  font-weight: 300 !important;
  font-style: normal;
  color: #000000;
  background-color: rgba(0, 0, 0, 0.45);
  width: max-content;
  padding:0rem 1rem;
}

.expertise-sec-p {
  font-family: "goudy-old-style", Georgia, Serif, serif;
  font-size: 16px;
  line-height: 26px;
  margin-top: 325px;
  font-style: normal;
  font-weight: lighter !important;
  color: #FFFFFF;
}

.link1:hover {
  color: white;
}

.expertise-future {
  padding: 64px;
}

.proj-view {
  border-bottom: 1px solid #ebebeb;
  width: 83px;
  cursor: pointer;
  font-family: "goudy-old-style", Georgia, Serif, sans-serif;
}

.proj-view:hover {
  border-bottom: 1px solid #ffffff;
}

.btn-view-industries-black {
  margin-top: 15px;
  width: 150px !important;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  margin-top: 325px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  margin-left: 0.5% !important;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  border: 1px solid #ffffff;
  color: #ffffff;
} 

.btn-view-industries-white {
  margin-top: 15px;
  width: 150px !important;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  margin-top: 325px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  margin-left: 0.5% !important;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  border: 1px solid #000000;
  color: #000000;
} 

.btn-view-industries-black:hover {
  color: #fff;
  background-color: #000;
}
.btn-view-industries-white:hover {
  color: #fff;
  background-color: #000;
}

.btn-views {
  margin-top: 15px;
  width: 150px !important;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 1px solid #9B9B9B;
  color: white;
  margin-top: 6px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
}

.btn-views:hover {
  color: #f5f5f5;
  background-color: #000;
}


@media (min-width: 320px) and (max-width: 768px) {
  .expertise-title {
    margin: 0%;
    text-align: center;
    width: 96%;
    margin-left: 6px;
  }

  .expertise-content {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -.2px;
    margin-top: 34px;
    font-family: "goudy-old-style", Georgia, Serif, serif;
  }

  .expertise-sec-h {
    font-size: 22px !important;
    line-height: 26px;
    letter-spacing: 0px;
    color: #ffffff !important;
    margin: 0px;
  }

  .expertise-sec-p {
    font-size: 16px;
    line-height: 26px;
    /* color: #414141 !important; */
    margin-top: 9px;
    border-color: 1px solid #9B9B9B !important;
  
  }

  .expertise-sec-p a {
    font-size: 16px;
    line-height: 26px;
    color: #414141;
    margin-top: 25px;
  }

  .expertise-sec {
    position: unset;
    margin-left: 0%;
    padding: 3% 5% 1.3% 5%;
    color: #000;
    width: auto;
  }

  .image-element {
    height: 100%;
    padding-bottom: 4%;
    margin-top: 17px !important;
  }

  .expertise-div{
    margin-left: -112px !important;
    padding-bottom: 66px !important;
  
  }

  .btn-view-industries-black  {
    border: 1px solid #9B9B9B !important;
  }

  .btn-view-industries-white  {
    border: 1px solid #9B9B9B !important;
  }

}




.expertise-para {

  font-family: 'SD Georgia Light';
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 43px;
 
  padding-bottom: 35px;
  color: #414141;
}


@media only screen and (max-width: 818px) {

  .expertise-div{
    margin-left: -47px;
  
  }
}

@media (min-width: 810px) and (max-width: 1080px){
  .image-element{
    height: auto;

  }
  .expertise-title{
    margin-right: 0px;
    margin-top: 15px;
    margin-bottom: 22px;
  }
  .expertise-sec{
    top: 70px;
  }
  .btn-view-industries-black{
    margin-top: 130px !important;
  }
  .btn-view-industries-white{
    margin-top: 130px !important;
  }
  .expertise-div{
    padding: 0px 0px 73px 190px !important;
  }
}

@media (min-width: 810px) and (max-width: 1320px){
  .expertise-div{
    
padding: 0px 0px 73px 132px !important;
  }
}