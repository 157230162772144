/* .CloseButton { */
    /* position: absolute !important; */
    /* left: 50px; */
    /* top: 10px;
    right: 30px; */
    /* position: absolute !important; */
    /* right: -38%; */
    /* display: block;
    left: 77%; */
    /* bottom: -26px; */
    /* top: 23px;
    cursor:pointer;
} */

.search {
    background-image: url("../../../../public/assets/images/casestudies/Search icon.svg");
    background-repeat: no-repeat;
    background-position: 12px 15px;
  }
.inputsearch{
    width: 57% !important;
    /* display: block; */
    /* margin: auto; */
    /* margin-left: -1%; */
    padding: 10px 50px 15px;
    outline: none;
    border-top: none;
    border-bottom: 1px solid whitesmoke !important;
    border-right: none;
    border-left: none;
    font-size: 16px;
    /* text-decoration: none !important; */
    }
    
    .inputsearch:hover{
      border-bottom: 1px solid black !important;
    }
  
    input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
   .searchmain{
    text-align:center;
   } 

 .closeIconMore {
  font-size:1.5em; 
  position: absolute;
  right: 16px;
  bottom: 16px;
  color: black;
  margin-left:-25px;
}
@media (min-width: 320px) and (max-width: 768px) {
    .inputsearch {
      width: 85% !important;
      font-size: 15px;
      }
      .closeIcon {
        position: absolute;
        right: 16px;
        bottom: 16px;
        color: black;
       }
  /* .CloseButton {
  top: 10px;
  left: 90%;
  } */
  .searchmain{
    text-align: center;
  }
}