.Client_container {
  margin-top: 40px;
}

.client-title {
  max-width: 700px;
  margin-left: 20%;
  padding-top: 35px;
  padding-bottom: 4.3%;
}

.client-heading {
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1.4px;
  line-height: 50px;
  margin: 0;
  padding-bottom: 20px;
}


.client-part {
  width: 100%;
  padding: 0% 2.8% 5.2% 2.8%;
}

.client-imghover {
  width: 100%;
}

.clienttitle-1 {
  color: #fff !important;
  font-family: akzidenz-grotesk-pro, Helvetica, Arial, Sans-Serif;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: -.2px;
  line-height: 18px;
  margin: 0;
  padding-bottom: 0;
  padding-top: 16px;
  text-align: left;
  word-break: break-all;
}

.clientsubtitle {
  color: #121212 !important;
  font-family: goudy-old-style, Georgia, Serif, serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  padding-top: 0;
}


:placeholder-shown {
  font-size: large;
  background-color: #dee2e6;
}

/* .search {
background-image: url("../../../public/assets/images/Clients_img/Search icon.svg");
background-repeat: no-repeat;
background-position: 12px 15px;
}

input[type="text"] 
{ border: none }

.inputsearch{
width: 45%;
margin-left: 20%;
padding: 10px 40px 10px;
outline: none;
border-top: none;
border-bottom: 1px solid whitesmoke !important;
border-right: none;
border-left: none;
}

.inputsearch:hover{
border-bottom: 1px solid black !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
display: none;
} */

.Client-img {

  margin-left: 1%;
}

.Client-imgs {

  padding: 16px;
}


.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .2s ease;
}



.no-client-found {
  padding-left: 40%;
  font-size: 22px !important;
  color: #121212;
}

.CloseButton {
  position: relative;

  top: -33px;

  cursor: pointer;
  left: 63%;
}

@media (min-width: 320px) and (max-width: 768px) {
  .client-title {
    text-align: center;
    margin: auto;
    padding-top: 36px;
    padding-bottom: 38px;
  }

  .inputsearch {
    width: 86%;
    margin-left: 6%;
    padding: 10px 40px 10px;
    outline: none;
    border-top: none;
    border-bottom: 1px solid whitesmoke !important;
    border-right: none;
    border-left: none;
  }

  .CloseButton {
    position: relative;

    top: -31px;
    left: 85%;

    cursor: pointer;
  }

  .client-part {
    padding: 0% 2.8% 11% 2.8%;
  }

  .Client-img {
    margin-left: 3.8%;
  }
}

@media (min-width: 769px) and (max-width: 920px) {
  .client-title {
    max-width: 490px;
    padding-bottom: 42px
  }

  .client-part {
    padding-bottom: 5.8%;
    padding-right: 15px;
  }
}