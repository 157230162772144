.benefits_container{
    margin-top: 40px !important;
}

.benefits {
    margin: 6% 0% 0% 0%;
}

.benefits-title {
    width: 43%;
    margin: -2.5% 0% 0% 18%;

}

.benefits-content p {
    font-size: 18px;
    font-weight: 300;
}

.benefits-H {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 20px;
    margin: 0;
    padding-bottom: 20px;
}

.benefits-content {
    width: 50%;
    margin: 3% 0% 0% 18%
}

.benefits-content h3 {
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 37px;
    line-height: 43px;
    letter-spacing: -0.04em;

    color: #fff;

}

.benefits-content p {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    line-height: 30px;
    margin: 3% 0% 0% 0%;

}

.benefits-img {

    margin: 3% 0% 0% 0%;
}

#benefits-part {
    width: 100%;
    padding: 5% 2.8% 5% 2.8%;
}

.benefitimg {
    width: 100%;
}

.benefitstitle {
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 130%;
   
    letter-spacing: 0.02em;
    color: #fff;
    margin: 1.5% 0% 0% 0%;
    text-transform: capitalize;
}

.benefits-imgs {
    padding: 16px 10px 16px 16px;
}

@media (min-width: 320px) and (max-width: 768px) {

    .benefits-title {
        width: 90%;
        margin: 10% 0% 0% 6%;

    }

    .benefits-part1{
        padding: 40px 40px 46px 45px !important;
    }

    .benefits-content {
        width: 90%;
        margin: 3% 0% 18% 6%;
    }

    .benefits-H {
        line-height: 40px;
    }

}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px){
    .benefits-H {
        padding-top: 25px;
    }
    .benefits-content{
        width: 67%;
        margin: 3% 0% 8% 18%;
    }

    .benefits-part1{
        padding: 40px 40px 50px 45px !important;
    }
   
}

#imghover {
    transition: all 1.5s ease;
}

#imghover:hover {
    transform: scale(1.05) !important;
}

.img-hover-zoom {
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}