.tea_mops{
   margin-top: 40px;
}

.web_container{
   margin-top: 40px;
}

.collaborative{
   margin-top: 40px;
}

.gaming{
   margin-top: 40px;
}

.enterprise_container{
   margin-top: 40px;
}

.augmented_intelligence{
   margin-top: 40px;
}

.biometrics{
   margin-top: 40px;
}

.pycharm{
   margin-top: 40px;
}

.blockchain{
   margin-top: 40px;
}

.cryptocurrency{
   margin-top: 40px;
}

.enterprise-img {
   width: 103%;
   object-fit: cover;
   border-style: none;
   display: block;

}

.enterprise-h1 {
   color: #414141;
   font-family: "goudy-old-style", Georgia, Serif, serif;
   font-weight: 400;
   font-size: 50px;
   line-height: 50px;
   letter-spacing: -1.4px;
}

.enterprise-h3 {
   color: #ffffff;
   font-family: "goudy-old-style", Georgia, Serif;
   font-weight: 400;
   font-size: 35px;
   line-height: 32px;
  
 
}

.enterprise-h4 {
   font-size: 13px;
   line-height: 18px;
   letter-spacing: -.2px;
   font-weight: 400;
  
}

.enterprise-block {
   padding-top: 17px;
   width: 92%;
   padding-bottom: 49px;
}




.enterprise-block1-span {
   font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
 
   color: #414141;
   font-size: 14px;
   line-height: 1.7;
}


.ElementEnterprise {
   width: 90% !important;
   justify-content: center;
   margin: auto;
   padding-bottom: 15px;
}

.enterpriseMain {
   padding-bottom: 4.5%;
}

.enterprise-block1 {
   width: 100%;
    padding: 135px;
   margin: auto;
 
   background-color: #F8F8F8;
 
}

.PHeading {
   color: #ffffff;
   margin: 0;
   font-family: "goudy-old-style", Georgia, Serif;
   font-weight: 400;
   font-size: 23px;
   line-height: 32px;
  
   padding-bottom: 25px;
}
.PSubHeading{
   font-size: 19px;
   font-family: "goudy-old-style", Georgia, Serif;
}

.ElementEnterpriseFirst {
   width: 90% !important;
   justify-content: center;
   margin: auto;
   padding-bottom: 15px;
}

.ItalicFont {
   font-size: 16px;
   line-height: 26px;
}

.btn-view {
  
   margin-top: 15px;
   width: 110px;
   height: 40px;
   font-weight: 400;
   font-size: 16px;
   line-height: 15px;
   border: 1px solid #9B9B9B;
   color: rgba(65, 65, 65, 0.85);
   cursor: pointer;
   background-color: transparent;
   border-radius: 0;

}

.ElementEnterprises {
   width: 90% !important;
   justify-content: space-between;
   margin: auto;
   padding-bottom: 15px;
   gap: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
   .ElementEnterprises {
      width: 90% !important;
      justify-content: end;
      margin: auto;
     
      padding-bottom: 15px;
      gap: 30px;
   }

   /* .ElementEnterpriseFirst {
      margin-right: 22px;
   } */

   .enterprise-img{
      width: 99.1%;
   }

   .enterpriseMain{
      padding-bottom: 38px;
   }
}

@media (min-width: 768px) and (max-width: 1024px) {

   .enterprise-block{
      padding-top: 13px;
      margin-left: -22px;
   }
   .ElementEnterprises {
      width: 90% !important;
      gap: 10px !important;
      padding-left: 0px;
   }

   .enterprise-block1 {
      width: 95% !important;
      padding: 10px !important;
   }
}

@media (min-width: 1025px) and (max-width: 1314px) {
   .ElementEnterprises {
      width: 90% !important;
      gap: 10px !important;
      padding-left: 0px !important;
   }

   .enterprise-block1 {
      width: 95% !important;
      padding: 10px !important;
   }
}

