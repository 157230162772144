.recruitement-process {
    box-sizing: border-box;
    margin-top: 40px !important;
}

.recruit-content {
    display: block;
    padding-left: 16% !important;
    width: 55% !important;
    margin: 42px 0px 0px 0px;
}

.recruitement-content-p {
    color: #fff;
    font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 30px;
    margin-bottom: 0px;
}

.recruitement-content-h1 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 37px;
    margin: 0;
    padding-bottom: 20px;
}

.recruitement-img {
    width: 100%;
    object-fit: cover;
    margin: 3.2% 0% 3% 0% ;

}

.recruit-content1 {
    width: 51%;
    margin: 0 auto;
}

.recruit-content1-p {
    color: #fff;
    font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 18px;
}

.recruit-content1-p1 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    padding-bottom: 15px;
}
.recruit-content2-p1{
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
}

.recruit-content1-p2 {
    color: #414141;
    font-family: 'goudy-old-style',Georgia,Serif;
    font-size: 19px;
    line-height: 26px;

}

.recruit-img-block{
   margin: 2% 2.5% 4% 2.5% !important;
}

.recruit-img-block1{
    width: 90%;
    display: block;
    margin: auto;
}

.recruit-content-block1{
    width: 45%;
    margin: 3% 0% 4% 27% ;
}

.recruit-content1-p3{
    color: #fff;
    font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 18px;
    
}

.img-block{
    padding: 0% 2% 4% 2% !important ;
}

.discover-btn{
    padding-top: 30px;
    padding-bottom: 73px;
    margin-left: 24.5%;
}

.discover-btn button{
 
  margin-top: 15px;
  width: 205px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 1px solid #9B9B9B;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
}
.discover-btn button:hover{
    background-color: #000000;
    color: white;
    outline: none;
}

@media (min-width: 320px) and (max-width: 768px) {

    .recruit-content{
        margin-bottom: 24px;
    }
    .recruitement-img{
        margin-bottom: 4%;
    }

    .recruit-img-block{
        margin: 2% 0.5% 4% 0.5% !important;
    }
    .recruit-content1 {
        width: 90%;
        padding-left: 2%;
    }
    #divrecruit img {
        width: 90%;
        margin-bottom: 2%;
    }
    .recruitement-content-h1{
line-height: 40px;
padding-top: 8px;
    }
    .recruit-content-block1{
        width: 92%;
        margin: 3% 0% 4% 6% ;
    }

    .recruitGrids{
        flex-direction: column;
        margin-left: 19px !important;
     }

     #divrecruit{
       
        display: flex;
        margin: auto;
        justify-content: center;
     }
        .discover-btn{
            margin-left: 7%;
            margin-bottom: -4px;
        }
        .recruitimg{
            padding-right: 2px;
        }
        }


.recruitGrid {
    max-width: 100% !important;
    justify-content: center;
    gap: 30px;
}
    .recruitGrids {
        width: 90%;
        display: flex;
        gap: 30px;
        margin: auto;
        /* margin-left: 59px; */
        justify-content: center;
    }

    .recruitGrids  img {
width: 100%;
    }


      .recruitimg{
        padding-top: 35px;
      }

      @media screen and (min-device-width: 768px) and (max-device-width: 1200px){
        .recruitement-content-h1{
            line-height: 37px !important;
                }
                .recruit-content{
                    margin-top: 40px;
                    width: 64% !important ;
                }

                .recruitGrids{
                    margin-left: 38px;
                }
                .discover-btn{
                    margin-left: 24.6%;
                }

               .recruit-img-block {
                    margin: 2% 0.5% 4% 0.5% !important;
                }

        .recruitement-img {
                    margin: 5.5% 0% 3% 0%;
                }
      }