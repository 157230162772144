.policy_container{
    margin-top: 40px !important;
}

.policy{
    margin: 6% 0% 0% 0%;
    height: 100%;
}

.policy-title{
    width: 60%;
    margin: -3.2% 0% 6% 15%;
}

.policy-title p{
    margin: 3% 0% 0% 15% !important;
    font-size: 19px;
    font-weight: 300;
    
}

.cookies-p{
    margin: 3% 0% 1% 15% !important;
    font-size: 18px;
    font-weight: 300;

    
}

.policy-title h4{
color: #121212;
font-size: 25px;
font-weight: 300;
margin: 0% 0% 0% 15%;
}

.list-ul{
    margin: 0% 0% 10% 17%;
    padding: 0% 0% 0% 0%;
    font-size: 19px;
    font-weight: 300;  
}

.list-ul>li{

    margin: 0% 0% 2% 0%;
    font-size: 19px;
    font-weight: 300;  
    color: #ffffff;
    font-family: "goudy-old-style", Georgia, Serif;;
}


.list1-ul{
    margin: 0% 0% 3% 17%;
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.list1-ul>li{

    margin: 0% 0% 2% 0%;
    font-size: 18px;
    font-weight: 300;  
}


.policy-p{
font-size: 19px;
font-weight: 300;
color: #ffffff;
margin: 0% 0% 10% 10% !important;
}



.policyHeading{
    margin: 0% 0% 0% 15%;
    font-size: 50px;
    font-weight: 300;
    color: #ffffff;
    font-family: "goudy-old-style", Georgia, Serif;;
}

.h-4{
    margin: -6% 0% 0% 15% !important;
}

.cookies-ol{
    margin: 2% 0% 10% 19%;
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.cookies-ol>li{

    margin: 0% 0% 2.5% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.rights-ol{
    margin: 2% 0% 10% 19%;
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.rights-ol>li{

    margin: 0% 0% 2.5% 0%;
    font-size: 18px;
    font-weight: 300;  
}


@media (min-width: 320px) and (max-width: 768px) {
.policy-title {
    width: 90%;
    margin: 0;
    margin-bottom: 62px;
    
}
.policy-title h4{
line-height: 30px;

}
.policyHeading{
    margin-top: 9%;
}
}

@media (min-width: 770px) and (max-width: 1080px){
.policy-title{
    margin-top: -18px;
    margin-bottom: 63px;
}
}


