/* .main {

} */
.StratergyAllience_container {
  margin-top: 40px;
}

.heading {
  display: flex;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1.4px;
  line-height: 20px;
  margin: 0;
  padding-top: 54px;
  padding-right: 85px;
  padding-bottom: 18px;
  padding-left: 50px;
}


.allience-part {
  width: 100%;
  padding: 5% 2.8% 4.3% 3.8%;
}

.allience-imgs {

  padding: 16px;
}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .2s ease;
}

.allience-imghover {
  width: 100%;
}

.alliencetitle {
  color: #fff !important;
  font-family: akzidenz-grotesk-pro, Helvetica, Arial, Sans-Serif;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: -.2px;
  line-height: 18px;
  margin: 0;
  padding-bottom: 0;
  padding-top: 8px;
  text-align: left;
  word-break: break-all;
  letter-spacing: -0.03em;

  color: #414141;
}


.alliencesubtitle {
  color: #fff !important;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  padding-top: 0;
}

@media (min-width: 320px) and (max-width: 768px) {
  .heading {
    line-height: 50px;
    padding-top: 43px;
    padding-left: 25px;
  }

  .allience-part {
    width: 100%;

    padding: 8% 2.5% 9% 5.8%;
  }



  .css-1tw2i9j-MuiGrid-root {
    margin-left: -8px;
  }
}


@media (min-width: 769px) and (max-width: 1080px) {
  .heading {
    padding-left: 25px;
    padding-top: 54px;
  }

  .allience-part {
    padding-left: 30px;
    padding-right: 14px;
  }
}