.divBlock {
  background-color: #000;
  width: 100%;
  height: 60px;

}


.navbar-collapse {
  flex-grow: unset !important;
}

.navbar {
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 95px;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ffffff;
  padding: 0px !important;
}

.nav-wrapper {
  padding: 32px 64px 32px !important;
}

.item {
  font-size: 13px;
}

.nav-item {
  margin-left: 15px;
  text-align: right;
}

.nav-logo {
  flex-grow: 1;
  text-align: left;
  color: black;
  margin-bottom: -1% !important;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif !important;
  font-size: 22px !important;
}

.nav-heading {
  margin-right: 40px !important;
  font-size: 13px !important;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif !important;

  cursor: pointer;
  color: black;
  text-align: left;
}

.more-vertical {
  color: black;
  margin-right: 20px;
  cursor: pointer;

}

.more-verticals {
  color: white;
  margin-right: 20px;
  cursor: pointer;
}

.header {
  background-color: #ffffff !important;
  z-index: 1 !important;
  height: 110px !important;
  box-shadow: none !important;
  height: 90px;
  object-fit: cover !important;
}

#main-header {
  position: sticky;
  top: 0;
  display: block;
  transition: top 1s;
}

.nav-a {
  color: #F8F8F8;
  text-decoration: none;
  font-family: "goudy-old-style", Georgia, Serif;
  line-height: 89px;
  font-size: 130px;
}

.nav-title {
  color: #717171;

  text-decoration: none;
}

.nav-title:hover {
  color: #121212;
}

.nav-a:hover {
  color: #717171;
  text-decoration: none;
}

.header-options {
  padding-top: 2rem;
}

.header-options a {
  font-size: 16px;
  opacity: 1;
  color: #ffffff;
  text-decoration: none;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  line-height: 26px;
  padding-right: 18px;
  transition: color 750ms;
  text-transform: uppercase;
}

.header-option a {
  font-size: 16px;
  opacity: 1;
  color: #fff;
  text-decoration: none;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  line-height: 26px;
  padding-right: 18px;
  transition: color 750ms;
  text-transform: uppercase;
}

.header-option a:hover {
  color: #F40009;
  text-decoration: none;
  font-weight: 600;
}

.header-options a:hover {
  color: #F40009;
  font-weight: 600;
  text-decoration: none;
}

.logo_svg_en {
  width: 130px;
  height: 111px;
  padding: -11px 14px 15px 14px !important;

}

.logo_svg_es {
  width: 170px;
  height: 111px;
  margin-left: -14px;
}

.header-toolbar {
  column-gap: 18px;
  padding-left: 33px !important;
  padding-right: 33px !important;
  align-items: center !important;
}

/* .forHomePage {
  top: 0px;
  background: #7bb9de !important; 
   position: static !important;
   box-shadow: none;
} */

.hp {
  background-color: #000 !important;
  z-index: 1 !important;
  height: 110px !important;
  box-shadow: none !important;
  height: 90px;
}


@media only screen and (max-width: 450px) {
  .header-toolbar {
    padding-left: 17px !important;

  }

  .nav-a {
    font-size: 40px;
    font-family: "goudy-old-style", Georgia, Serif;
  }
}

@media only screen and (max-width: 768px) {
  .header-toolbar {
    padding-right: 20px !important;

  }
}

@media only screen and (min-width: 1441px) {
  .header-toolbar {
    max-width: inherit !important;

  }
}

/* updated header css */

.header_banner_bg {
  /* background: url('../../../public/assets/images/navbar/navbarbg.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.header_menu {
  background-color: #000000;
  color: #fff;
  height: 300px;
}


.forHomePage {
  top: 0px;
  background: transparent !important;
  position: static !important;
}

.carouselhead h3 {
  color: #292929 !important;
  font-size: 38px !important;
  letter-spacing: 0.20em;
  line-height: 130%;
  font-weight: 400;
  margin: 0;
  padding-left: 40px;
  padding-top: 8rem;
  text-align: center;
  text-transform: uppercase;
  font-family: "D-DIN" !important;
}

.bannergrid {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}


@media screen and (min-device-width: 320px) and (max-device-width: 455px) {

  .header_banner_bg {
    /* background: url('../../../public/assets/images/navbar/mobilebg.svg'); */
    /* background-repeat: no-repeat;
    background-size: cover;
    width: 100%; */
    background-color: #ffffff;
  }

  .header_menu {
    background-color: #000000;
    height: 300px
  }

  .bannergrid {
    padding-top: 25px !important;
  }

  .carouselhead {
    left: 7%;
    top: 33%
  }

  .carouselhead h3 {
    text-align: left;
    font-size: 13px !important;
    font-weight: 400;
    color: #292929 !important;
    line-height: 104%;
    letter-spacing: 0.20em;
    padding-top: 3rem;
    margin: 0px;
    font-family: "D-DIN" !important;
    margin-top: -50px;
    margin-left: -7px !important;
  }
}

@media screen and (min-device-width: 456px) and (max-device-width: 900px) {

  .carouselhead h3 {
    text-align: center;
    font-size: 26px !important;
    font-weight: 400;
    color: #292929 !important;
    line-height: 120%;
    letter-spacing: 0.20em;
    margin: 0px;
    font-family: "D-DIN" !important;
    padding-top: 0rem;
    margin-left: -7px !important;
  }

  .header_menu {
    background-color: #000000;
    height: 300px
  }

}

@media screen and (min-device-width: 901px) and (max-device-width:1230px) {

  .carouselhead h3 {
    text-align: center;
    font-size: 28px !important;
    font-weight: 400;
    color: #292929 !important;
    line-height: 114%;
    letter-spacing: 0.20em;
    margin: 0px;
    font-family: "D-DIN" !important;
    margin-top: -50px;
    margin-left: -7px !important;
  }

  .header_menu {
    background-color: #000000;
    height: 300px
  }

}

/* Header.module.css */
.header_carousel_container {
  position: relative;
  height: 85px;
  background-color: #000;
}

.header_content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.carousel_content {
  height: 660px;
  position: absolute;
  top: -131px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media screen and (min-device-width: 320px) and (max-device-width:506px) {
  .carousel_content {
    height: 260px;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

}
@media screen and (min-device-width: 320px) and (max-device-width:506px) {
  .carousel_content {
    height: 260px;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

}
@media screen and (min-device-width: 694px) and (max-device-width:768px) {
  .carousel_content {
    height: 260px;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

}