.location-main-title {
  width: 91%;
  margin: auto;
  padding-top: 37px;
  margin-bottom: -36px !important;
}

.location_container{
  margin-top: 40px;
}

#imghover{
    transition: all 1.5s ease;
  }
  #imghover:hover {
    transform: scale(1.05) !important;
  }
  
  .img-hover-zoom{
    overflow: hidden;
  }
  
  .img-hover-zoom img {
    transition: transform .5s ease;
  }

  .location-title{
    font-size: 50px;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
  }

  .location-container {
    padding: 4.5% !important;
    margin-bottom: 29px;
  }
  .img-res {
   
    width: 100%; 
    object-fit:cover;
  }
  .img-sub-h {
    font-weight: 400;
    color: #fff !important;
    font-size: 25px;
    line-height: 31px;
    padding-top: 10px;
    /* margin-top: -3%; */
    font-family: "goudy-old-style", Georgia, Serif;
  }
  .img-sub-p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding-top: 5px;
    line-height: 18px;
    letter-spacing: -0.2px;
    margin: 0px;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  }

  .img-sub-p1 {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding-top: 5px;
    line-height: 18px;
    letter-spacing: -0.2px;
    margin: -1% 0% 0% 0%;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
    width: 50%;
  }
  .img-sub-p2 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    padding-top: 5px;
    line-height: 18px;
    letter-spacing: -0.2px;
    margin: 3% 0% 0% 0%;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  }
  .location-content {
    height: 450px !important;
    cursor: pointer;
    height: 100% !important;
  }
  
 
  
  @media screen and (min-device-width: 360px) and (max-device-width: 500px){
    .location-main-title{
      padding-top: 41px;
      padding-bottom: 23px;
      width: 93%;
      /* line-height: -18px !important; */
    }

    .location-title{
      font-size: 45px;
    }

    .location-container{
      margin-bottom: 42px;
      padding-right: 20px !important;
    }

  }

  
  @media screen and (min-device-width: 768px) and (max-device-width: 1080px){
    .location-main-title{
      padding-top: 35px;
      padding-bottom: 3px;
      letter-spacing: -1.4px;
    }

    
  }
  
  
  