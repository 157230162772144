.newsEvent_container{
    margin-top: 40px;
}
.body{
    background-color: black;
}

.news-title {
    max-width: 700px;
    margin-left: 27%;
    padding-top: 53px;
    padding-bottom: 2%;
}

.newstitle {
    font-size: 23px;
    font-family: "goudy-old-style", Georgia, Serif;
    margin-top: 2%;
    color: #fff;
}

.newsimages{
    padding: 10px;
}



.newssubtitle {
    margin-top: -3%;
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 28px;
    color: white;
}


.newsHeading-1 {
    font-family:  "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 18px;
    letter-spacing: -0.03em;
    color: #fff;
}


.news-content {
    max-width: 700px;
    margin-left: 22%;
    margin-top: 1%;
    margin-bottom: 5.5%;
}

.news-contentp {
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 32px;
    text-align: justify;
    color: #fff;
    padding-left: 70px;
}

.news-H {
    padding-top: 74px !important;
    font-family: "goudy-old-style", Georgia, Serif;
    padding: 1px;
    margin-left: 5%;
    font-size: 23px;
   
    color: #fff;
    margin-bottom: 6.5%;
    
}

.news-part {
    width: 100%;
    padding: 0% 4% 5% 5%;
    margin-top: 70px !important;
    justify-content: end;
   
}



.news-img {
    background-color: #f5f5f5 !important;
    width: 100%;
  
}

.events-img{
    width: 100%;
    /* margin-bottom: 23px; */
}

.abouttitle1 {
    margin-top: 1%;
    line-height: 18px;
}

.link {
    color: black;
}

.news-img {
    padding: -2% !important;
}


.aboutUs2-img {
    margin-top: -6%;
}

.img-hover-zoom{
    overflow: hidden ;
  }
  
  .img-hover-zoom img {
    transition: transform .5s ease;
  }

  #imghover{
    transition: all 1.5s ease;
  }
  #imghover:hover {
    transform: scale(1.05) !important;
  }




.events-H {
    padding-left: 10px;
    margin-left: 4.5%;
    /* margin-top: 60px */
    padding-top: 74px;
    font-size: 23px;
    padding-bottom: 28px;
    font-family: "goudy-old-style", Georgia, Serif;
    color: #fff;
   
}

.events-part {
    width: 100%;
    padding: 5% 4% 5% 5%;
    
    margin-top: -3%;
    margin-bottom: -14px;
    padding-bottom: 4.5%;
    justify-content: end;
}

.eventsimages {
    padding: 10px;
   
}



.eventstitle {
    font-size: 23px;
    font-family: "goudy-old-style", Georgia, Serif;
    margin-top: 1.5%;
    color: #fff;
}

.eventssubtitle {
    margin-top: -2%;
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 28px;
    color: #fff;
}

.btn-view{
    margin-top: 15px;
    margin-right: 10px;
    width: 130px;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    border: 1px solid #9B9B9B;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
}
.btn-view:hover {
    color: #f5f5f5 ;
    background-color: #121212;

}

@media (min-width: 320px) and (max-width: 768px) {

    .news-H{
        padding-top: 30px !important;
    }

    .events-H{
        padding-top: 30px !important;
        padding-bottom: 57px;
        margin-left: 3.5%;
        color: #fff;
    }

    .btn-view{
        margin: 0% 2% 0% 70%;
        width: 95px;
        padding: 0px;
    }
    .contRes{
        padding-right: 18%;
        }
        .newsHeading-1 {
            line-height: 40px;

        }
        .news-contentp {
            padding-left: 5px;
            padding-bottom: 12px;
        }

        .news-title{
            margin-left: 23%;
            padding-top: 41px;
        }

        .news-part{
            padding: 0% 3.8% 5% 6%;
        }

        .events-part{
            margin-bottom: 50px;
            padding-right: 16px;
            padding-left: 30px;
        }
        .eventsimages {
            padding: 10px 8px 10px 10px !important;
           
        }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .contRes{
        padding-right: 18%;
        }

        .btn-view{
            margin:0% 1% 0% 85.8% !important;
           
            width: 95px;
            padding: 0px;
        }
        .newsHeading-1 {
            line-height: 40px;

        }
        .news-contentp {
            padding-left: 5px;
        }

        .news-title{
            margin-left: 23%;
            padding-top: 46px;
        }

        .news-part{
            padding: 0% 5.1% 5% 6.2%;
        }

        .events-part{
            margin-bottom: 36px;
            padding-right: 16px;
            padding-left: 30px;
        }

        .news-H{
            margin-left: 6%;
        }

        .events-H{
            margin-left: 2.3%;
            padding-bottom: 35px;
        }
    
        .eventsimages{
            padding: 17px;
        }
      }


.css-mhc70k-MuiGrid-root{
    margin-top: -77%;
}