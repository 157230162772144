.form-block {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 68px;
    background-color: #000;
}

.reactout-with-us {
    background-color: #f5f5ff;
}

.text-fields {

    width: 500px;
    margin: 2% 0% 0% 0% !important;
}

.text-fields input {
    outline: none;
    border-color: #f5f5ff !important;
    background-color: white !important;
    height: 35px;
    border-bottom: none;
}

.text-fields fieldset::after {
    border-bottom: none;
}

.text-fields fieldset {
    border-color: transparent;
    border-radius: 0px;
    outline: none !important;
}

.text-fields fieldset:hover,
.text-fields fieldset:focus,
.text-fields fieldset:active,
.text-fields fieldset:visited {
    outline: none !important;
    border-color: transparent !important;
}

.MuiOutlinedInput-root {
    border-radius: 0px !important;

}

.MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    outline: none;
}


.form-label {
    color: #fff;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 30px;
    margin-right: 0px !important;
}

.acknowledge {
    color: #fff;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, Sans-Serif;
    font-size: 19px;
    line-height: 26px;
    width: 80%;
    padding-top: 44px;
}

.acknowledge span {
    color: #414141;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, Sans-Serif;
    font-size: 19px;
    line-height: 26px;

    color: #fff;
}

.check {
    accent-color: rgba(65, 65, 65, 0.85) !important;
    color: white;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 15px;
}

.MuiCheckbox-root {
    padding: 0 !important;
}

.Reach-H {
    display: flex;
    /* margin-left: -400px !important; */
    /* justify-content: center; */
    padding-top: 30px;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 20px;
    margin-left: -3px;
    /* margin: 0; */
    padding-bottom: 50px;

}


@media (min-width: 320px) and (max-width: 625px) {
    .text-fields {
        width: 90%;
    }

    .form-label {
        width: -webkit-fill-available;
        padding-left: 43px;
        padding-right: 10px;
    }

    .Reach-H {
        padding-left: 38px;
    }

    /* .form-block{
        padding-left: 12px;
        padding-right: 0px;
    }  */


}

.error {
    color: red;
    font-size: 1rem;
    /* margin-top: 0.5rem; */
    display: flex;
    padding-top: 10px;
}

.contactButton {
    border: 1px solid rgba(65, 65, 65, 0.85) !important;
    color: #fff !important;
    border-radius: 0 !important;

}

.checkboxItems {
    display: flex !important;
    gap: 5px !important;
    width: 95% !important;
    margin-bottom: 15px;
    margin-left: -12px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: none !important;
}

@media (min-width: 320px) and (max-width: 780px) {
    .contactButton {
        left: 48px !important;

    }

    .checkboxItems {
        padding-left: 45px;
}
    

    .Reach-H {
        padding-left: 46px;
    }
}

.termPriv {
    text-decoration: underline;
    font-weight: 500 !important;
    cursor: pointer;
}


@media (min-width: 810px) and (max-width: 1080px) {

    .form-block{
        justify-content: space-around;
        padding-left: 45px;
    }


}