



.test h3 {
    color: #fff;
    margin: 0;
    font-family: "goudy-old-style", Georgia, Serif;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    padding-bottom: 10px;
  }

  
  .test h4{
    color: #fff;
    font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -.2px;
    font-weight: 400;
  }
  .test h1 {
    font-family: "goudy-old-style", Georgia, Serif;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 25px;
    letter-spacing: -1.4px;
    color: #fff !important;
  }
  .test p {
    font-size: 19px;
    line-height: 30px;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    padding-top: 5px;
  }
  .tests p {
    font-size: 28px;
    line-height: 30px;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    padding-top: 5px;
  }
  .stratHead {
    width: 73% !important;
    padding: 40px;
    display: block;
    margin: auto;
  }
  
  .stretVedio {
    display: block;
   
    margin: auto;
    padding-bottom: 46px;
  
  }
  .stretVedios {
    display: flex;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 64px;
    padding-left: 32px;
    padding-right: 32px;
}
     
  
.imgText {
    
    display: flex;
    gap: 80px;
    width: fit-content;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.imgTexts {
   
    display: flex;
    gap: 30px;
    width: 100%;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.btn-view {
     
      margin-top: 10px;
      width: 105px;
      height: 33px;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      border: 1px solid #9B9B9B;
      color: rgba(65, 65, 65, 0.85);
      cursor: pointer;
      background-color: #f5f5f5;
      display: block;
      margin: auto;
      margin-bottom: 30px;
 
 }

 .btn-view:hover{
    color: #f5f5f5;
    background-color: #121212;
 }

.twoImgTextGridMain {
    width: 85%;
    margin: auto;
}

.testimonialsimages {
    padding: 10px;
    
}

.testimonials-img {
    width: 100%;
    margin-top: 8%;
}

.testimonialstitle {
    font-size: 16px;
    font-family: Helvetica Light;
    margin-top: 2%;
    padding-top: 7px;
    padding-bottom: 10px;
}

.testimonialssubtitle {

    margin-top: -5%;
    font-family: 'SD Georgia Light';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
} 

.Insights {
   
    max-width: 700px;
    display: block;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
}
.Insightss {
   
  max-width: 1100px;
  display: block;
  margin: auto;
  padding-left: 25px;
  padding-right: 10px;
}

.img-hover-zoom1{
    overflow: hidden;
  }
  
  .img-hover-zoom1 img {
    transition: transform .2s ease;
  }
  
  .img-hover-zoom1:hover img {
    transform: scale(1.1);
  }
  
  #imghover{
    transition: all 1.5s ease;
  }
  #imghover:hover {
    transform: scale(1.05) !important;
  }

@media (min-width: 320px) and (max-width: 768px) {
.imgText{
    display: block;
    padding-bottom: 0;
    }
.imgText img {
  margin: auto;
  padding-left: 39px;
  padding-bottom: 9px;
}
.tests img {
  padding-right: -2px;
  height: 187px;
}

.stratHead {
    padding: 20px 10px 20px 25px !important;
}

.testimg{
  width: 95%;
}
    .twoImgTextGridMain{
      width: 100%;
      /* margin-left: 16px; */
    }
    .stretVedio  img {
      width: 100%;
      /* margin-bottom: 2%; */
      margin-top: -28px;
  }

    .Insights {
      padding-left: 34px;
    padding-right: 21px;
    margin-top: -20px
    }
    .Insightss {
      padding-left: 34px;
    padding-right: 21px;
    margin-top: -20px
    }

    .test h1{
      line-height: 42px;
    }
    .test h3 {
      padding-left: 40px;
    }

    /* .test p{
      padding-left: 39px;
    } */

    .imgTexts {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
        display: flex;
        width: 100%;
        padding: 0;
        align-items: center;
        flex-direction: column !important;
    }
  .stretVedio {
        display: block;
        width: 100%;
        margin: auto;
        padding-top: 32px;
        padding-bottom: 64px;
       
        padding-right: 0px;
    }

    .textp{
      padding-left: 40px;
    }

    .texth3{
      padding-left: 16px !important;
    width: 95%;
    }

    .testimonialsmain{
      margin-bottom: 64px !important;
      margin-top: 40px !important;
    }

    /* .test{
      line-height: 42px;
      margin-left: 28px;
    } */

  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1200px){
.stretVedios{
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 28px;
  padding-bottom: 42px;
}

.stretVedio{
  padding-top: 0px;
  padding-bottom: 45px
}

.imgTexts{
  padding-bottom: 0;
  padding-top: 0;
  height: 300px;
}

.testimonialsmain{
  margin-bottom: 62px !important;
  testimonialsmain:40px
}

.testimage{
  padding-top: 58px !important;
}

.testimages{
  margin-top: -27px;
}
  }

 