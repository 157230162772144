.img-hover-zoom {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform 0.5s ease;
}

.carouselhead h3 {
  color: #292929 !important;
  font-size: 40px !important;
  letter-spacing: 0.25em;
  line-height: 130%;
  font-weight: 700;
  margin: 0;
  padding-bottom: 0;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase;
  font-family: "D-DIN" !important;
}

.carousel-p {
  font-family: "goudy-old-style", Georgia, Serif, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 31px;
  letter-spacing: -0.03em;
  color: #141414;
  padding-top: 101px;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.arrow {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.carouselhead {
  height: 55%;
  left: 2%;
  position: absolute;
  top: 23%;
  width: 45%;
}

.css-1m9128y {
  width: 100%;
  margin-top: -45px !important;
  text-align: center;
  opacity: none;
  visibility: visible !important;
}

.css-1m9128y button {
  visibility: visible !important;
}

.container_carousel {
  position: relative;
}

.carou_desc {
  position: absolute;
  top: 0%;
  right: 2%;
  width: 70%;
  bottom: 0%;
  background-color: #000000;
  opacity: 43%;
  height: 299px;
  max-width: 700px;
  font-family: "goudy-old-style", Georgia, Serif;
  padding: 2rem;
}

@media not all and (min-resolution:.001dpcm) {
  .carou_desc {
    /* Safari-specific styles */
    /* position: absolute; */
    top: 0%;
    right: 5%;
    width: 40%;
    background-color: #F40009;
    opacity: 75%;
    height: 400px;
    max-width: 400px;
    font-family: 'TT Hoves', sans-serif;
    padding: 2rem;
  }
}

.arrowContainer {
  position: absolute;
  bottom: 1.1rem;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  align-items: center;
}

/* Style for the arrow icons*/
.arrowContainer div {
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
}

.carou_desc span {
  font-family: "goudy-old-style", Georgia, Serif;
  font-weight: bold;
  color: #ffffff !important;
  opacity: 100%;
  line-height: 130%;
}



.home-container {
  padding: 4.5% !important;
  margin-bottom: 22px;
}

.img-res {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

.img-sub-h {
  font-weight: 400;
  color: #fff;
  font-size: 25px;
  line-height: 31px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0%;
  font-family: "goudy-old-style", Georgia, Serif;
}

.img-sub-p {
  text-align: left;
  margin-top: 2px;
  padding-top: 8px;
  padding-bottom: 0px;

  font-size: 16px !important;
  font-weight: 400;
  color: #fff;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin: 0px;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  word-break: break-all;
}

.home-content {
  width: 638px !important;

  cursor: pointer;
  height: 100% !important;
}

.carousel-items img {
  max-height: fit-content !important;
  width: 100% !important;
}

.custom_indicator {
  position: relative;
  bottom: 10px;
  left: 88%;
  /* top: 8%; */
  bottom: 0%;
  max-width: 35px;
  cursor: pointer;
  z-index: 1;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif
}

/* .iconForward:hover{
  color: #F40009;
} */

.iconForward {
  color: #fff;
}

.iconBackward {
  color: #fff;
}

/* .iconBackward:hover{
  color: #F40009;
} */

.shortDec {
  font-size: 16px;
  position: absolute;
  bottom: 8rem;
  color: #121212;
  transform: color 750ms;
}

.shortDec:hover {
  font-weight: 900 !important;
  color: #F40009;
}

.indicator {
  /* width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px 0px 0px 0px;
  opacity: 1; */
  width: 25px;
  padding-bottom: 10px;
  /* cursor: pointer; */
  border-top: 3px solid #fff;
  transition: width 0.4s ease;
}

.indicator:hover {
  width: 80%;
  border-top: 3px solid #fff;
}

.custom_indicator_p {
  font-weight: 900 !important;
  width: 100px;
}

.carou_sub_text {
  /* font-family: "Poppins-regular" !important; */
  font-size: 18px !important;
  color: #fff;
  font-Weight: 400;
  line-height: 1rem;
}

.carou_main_text {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  line-height: 2rem;
  /* font-family: Poppins !important; */
}

.carou_hr {
  width: 50px;
  height: 3px;
  /* color: #fff; */
  opacity: 1;
  margin: 10px;
  border-top: 3px solid;
}

.bg_text_overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
} */

.carousel_container {
  height: 660px;
}

@media screen and (min-device-width: 600px) and (max-device-width: 768px) {
  .carou_main_text {
    font-size: 25px !important;
  }

  .carou_sub_text {
    font-size: 13px !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .carou_main_text {
    font-size: 25px !important;
  }

  .carou_sub_text {
    font-size: 14px !important;
  }

  .carou_desc {
    display: block;
    top: 65%
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 455px) {
  .home-container {
    margin-bottom: 39px;
    /* padding-right: 22px !important; */
  }

  .carousel-items img {
    background: #7bb9de;
    width: 100% !important;
  }

  .carousel-p {
    bottom: 5% !important;
  }

  .carouselhead {
    left: 7%;
    width: 44%;
  }

  .carouselhead h3 {
    text-align: left;
    margin-top: 2px;
    font-size: 16px !important;
    font-weight: 600;
    color: #292929 !important;
    line-height: 90%;
    letter-spacing: 0.1em;
    margin: 0px;
    font-family: "D-DIN" !important;
    margin-top: -50px;
    margin-left: -7px !important;
    width: 50% !important;
  }

  .carou_desc {
    display: block;
    top: 30%
  }

  .carou_hr {
    width: 23px;
  }

  /* .custom_indicator{
    display: none;
  } */
}

@media screen and (max-device-width:768px) {
  .custom_indicator {
    bottom: 5%;
    left: 68%;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 900px) {
  .custom_indicator {
    left: 91%;
    top: 79px;
  }
}

@media screen and (min-device-width: 456px) and (max-device-width: 900px) {
  .carousel-items img {
    background: black;
    max-height: 100% !important;
    width: 100% !important;
  }

  .carousel-p {
    bottom: 5% !important;
  }

  .carouselhead {
    width: 44%;
    left: 5%;
  }

  .carouselhead h3 {
    text-align: left;
    margin-top: 2px;
    font-family: "D-DIN" !important;

    font-size: 28px !important;
    font-weight: 400;
    color: #121212 !important;
    line-height: 118%;
    letter-spacing: 0.2em;
    margin: 0px;
    margin-top: -50px;
  }
}

@media screen and (min-device-width: 901px) and (max-device-width: 1230px) {
  .carousel-items img {
    height: 100% !important;
    width: 100% !important;
  }

  .carousel-p {
    bottom: 5% !important;
  }

  .carouselhead h3 {
    text-align: center;
    margin-top: 2px;

    font-size: 31px !important;
    font-weight: 400;
    color: #292929 !important;
    line-height: 138%;
    letter-spacing: 0.2em;
    margin: 0px;
    font-family: "D-DIN" !important;
  }

  .custom_indicator {
    left: 92%;
    top: 112px;
  }
}

@media only screen and (max-width: 769px) {
  .video-text {
    color: #000;
    position: unset;
    padding: 4%;
  }

  .video-element {
    height: 0%;
  }

  .img-sub-h {
    color: #fff;
  }
}

@media only screen and (min-width: 1441px) {
  .video-element {
    height: 700px;
  }

  .myVideo {
    height: 100%;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .video-element {
    height: 600px;
  }

  .myVideo {
    height: 100%;
  }
}

@media only screen and (min-width:1248px) {
  .carou_main_text {
    font-size: 34px !important;
  }

  .carou_desc {
    top: 44% !important;
  }

  .carou_sub_text {
    font-size: 19px !important;
  }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .shortDec {
    color: #121212;
  }
}

@media only screen and (min-width:281px) and (max-width:768px) {
  .carou_hr {
    width: 20px;
  }

  .shortDec {
    color: #121212;
  }
}


/* New media queiries */
@media only screen and (max-width:374px) {
  .carou_desc {
    top: 5% !important;
    right: 7.5% !important;
    width: 70% !important;
    height: 290px !important;
  }

  .carou_main_text {
    font-size: 15px !important;
    line-height: 1;
  }

  .carou_sub_text {
    font-size: 13px !important;
    line-height: 1 !important;
  }

}

@media only screen and (min-width:375px) and (max-width:424px) {
  .carou_desc {
    top: 2% !important;
    right: 9% !important;
    width: 70% !important;
    height: 370px !important;
  }

  .carou_main_text {
    font-size: 20px !important;
    line-height: 1;
  }

  .carou_sub_text {
    font-size: 15px !important;
    line-height: 1 !important;
  }

}

@media only screen and (min-width:425px) and (max-width:767px) {
  .carou_desc {
    top: 2% !important;
    right: 10% !important;
    width: 70% !important;
    height: 435px !important;
  }
}

/* End */

@media only screen and (max-width:280px) {
  .carou_main_text {
    font-size: 15px !important;
  }

  .carou_desc {
    top: 5% !important;
    left: 5% !important;
    width: 90% !important;
  }

  .carou_sub_text {
    font-size: 10px !important;
  }

  .carou_hr {
    width: 20px;
  }

  .shortDecMobile {
    color: #121212;
    bottom: 27%;
    font-size: 10px;
  }

  .arrowContainerMobile {
    bottom: 29%;
  }
}

@media only screen and (min-width:1025px) and (max-width:1248px) {
  .carou_main_text {
    font-size: 30px !important;
  }

  .carou_desc {
    top: 10% !important;
  }

  .carou_sub_text {
    font-size: 17px !important;
  }
}

@media only screen and (min-width:769px) and (max-width:910px) {
  .carou_desc {
    top: 32% !important;
    width: 60%;
    height: 51%;
  }

  .carou_main_text {
    font-size: 25px !important;
  }

  .carou_sub_text {
    font-size: 15px !important;
  }
  .arrowContainerMobile {
    bottom: 19%;
  }
}