.policy{
    margin-bottom: 70px;
    margin-top: 40px !important;
    height: 100%;
}

.policy-title{
    width: 52%;
    margin: 0% 0% 0% 23.5%;
}

.policy-title p{
    font-size: 19px;
    font-weight: 300;
    padding: 9px;
    
}

.cookies-p{
    font-size: 19px;
    font-weight: 300;

}

.policy-span{
    font-weight: bold;
    color: white;
}


.policy-title h4{
color: #121212;
font-size: 25px;
font-weight: 300;
padding: 9px;
}

.list-ul{
    padding: 0% 0% 0% 3%;
    font-size: 19px;
    font-weight: 300;  
}

.list-ul>li{
    font-size: 19px;
    font-weight: 300;  
    color: white;
}


.list1-ul{
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.list1-ul>li{
    font-size: 18px;
    font-weight: 300;  
}


.policy-p{
font-size: 18px;
font-weight: 300;
/* color: #121212; */
}



.policyHeading{
    font-size: 50px;
    color: white;
    font-weight: 300;
}


.cookies-ol{
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.cookies-ol>li{
    font-size: 18px;
    font-weight: 300;  
}

.rights-ol{
    padding: 0% 0% 0% 0%;
    font-size: 18px;
    font-weight: 300;  
}

.rights-ol>li{
    font-size: 18px;
    font-weight: 300;  
}


@media (min-width: 320px) and (max-width: 768px) {
.policy-title {
    width: 90%;
    margin: 0;
    margin-top: 36px;
    
}
.policy-title h4{
line-height: 30px;

}
.policy{
    padding-left: 41px;
    margin-bottom: 36px;
}
}

@media (min-width: 769px) and (max-width: 1080px){
    .policy{
        margin-bottom: 40px;
    }
}




.mailto a {
    text-decoration: none;
}

.h-4{
    color: white !important;
}
