.aboutUs-title {
  max-width: 700px;
  margin-left: 16%;
  padding-top: 38px;
  padding-bottom: 2%;
}

.aboutus-container {
  width: 100%;
  margin-top: 40px !important;
}

.abouttitle {
  text-align: left;
  margin-top: 2px;
  padding-top: 8px;
  padding-bottom: 0px;

  font-size: 16px !important;
  font-weight: 400;
  color: #fff !important;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin: 0px;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  word-break: break-all;
}

.aboutsubtitle {
  font-weight: 400;
  color: #fff !important;
  font-size: 25px;
  line-height: 31px;
  padding-top: 0px;
  font-family: "goudy-old-style", Georgia, Serif;
}

.aboutsubtitle2 {
  font-weight: 400;
  color: #fff !important;
  font-size: 25px;
  line-height: 31px;
  padding-top: 0px;
  font-family: "goudy-old-style", Georgia, Serif;
}

.aboutheading1 {
  color: #fff;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -.2px;
  font-weight: 400;
}

.aboutheading5 {
  font-weight: 500;
}

.aboutheading2 {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 25px;
  letter-spacing: -1.4px;
  color: #fff !important;
}

.aboutUs-content {
  max-width: 800px;
  margin-left: 24%;
  margin-top: 3%;
}

.aboutUs-contentp {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 32px;
  /* text-align: justify; */
  color: #fff;
}

.aboutUs-contentp1 {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 00;
  font-size: 19px;
  line-height: 26px;
  color: #fff;
}

.aboutUs-contentp1 p {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 00;
  font-size: 19px;
  line-height: 26px;
  color: #fff;
}

.about-part {
  width: 100%;
  padding: 5% 3.5% 4% 3.5%;
  margin: 0px;
}



.about-part2 {
  padding-top: 1% !important;

}

.about-items {
  margin: 20px;
}

.about-img {
  width: 100% !important;
}

.abouttitle1 {
  margin-top: 1%;
  line-height: 18px;
}

.link {
  color: black;
}

.aboutUs-img {

  padding: 0px 6px 0px 14px;
}

.aboutimages {
  padding: 10px;

}

.aboutUs2-img {
  margin-top: -6%;
  padding: 12px;
}



.img-hover-zoom {
  overflow: hidden;
}

/* .img-hover-zoom1 {
  overflow: hidden !important;
}  */

.img-hover-zoom img {
  transition: transform .5s ease;

}

/* #imghover{
  transition: all 1.5s ease;
}
#imghover:hover {
  transform: scale(1.05) !important;
} */




.img-width {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}


@media screen and (max-width: 349px) {
  .aboutUs-contentp {
    margin-left: -10%;
  }
  .aboutUs-contentp1 {
    margin-left: -10%;
  }
  .aboutheading1{
    margin-left: -5%;
  }
  .aboutheading2{
    margin-left: -7%;
    margin-bottom: 10% !important;
  }
}

@media screen and (min-device-width: 350px) and (max-device-width: 768px) {
  .aboutUs-title {
    width: 100%;
    padding-left: 10%;
  }

  .aboutUs-content {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    margin-left: 0%;
    margin-top: 35px;
  }

  .aboutUs-title {
    margin-left: 0%;
  }

  .aboutus-container {
    width: 100%;
  }

  .aboutheading2 {
    line-height: 43px;
  }

  .aboutUs2-img {
    margin-bottom: 12px;
  }

  .about-part2 {
    padding-right: 9px;
    padding-left: 17px;
  }

}

@media screen and (min-device-width: 770px) and (max-device-width: 1100px) {
  .aboutUs-title {
    width: 100%;
    padding-left: 10%;
  }

  .about-part2 {
    padding-right: 23.3px;
    padding-left: 30px;
  }

  .aboutUs-contentp {
    word-spacing: -1px;
  }


  .aboutUs-content {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    margin-left: 0%;
    margin-top: 35px;
  }

  .aboutUs-title {
    margin-left: 0%;
  }

  .aboutus-container {
    width: 100%;
  }

  .aboutheading2 {
    line-height: 25px;
  }


}


@media screen and (min-device-width: 1450px) and (max-device-width: 2150px) {

  .aboutUs-contentp {
    padding-right: 30px !important;
  }

}


@media screen and (min-device-width: 1450px) and (max-device-width: 2150px) {
  .abouttitle {
    padding-top: 4px !important;
  }
}

@media screen and (min-device-width: 2151px) and (max-device-width: 2810px) {
  .aboutsubtitle {
    padding-top: 20px !important;
  }

  .abouttitle {
    padding-top: 7px !important;
  }
}

@media screen and (min-device-width: 2811px) and (max-device-width: 3840px) {
  .aboutsubtitle {
    padding-top: 50px !important;
  }

  .abouttitle {
    padding-top: 7px !important;
  }
}