.form1-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
}

.formsub {
    width: 24%;
    padding-bottom: 16px;
}

.text1-fields {
    width: 500px;
    margin: 2% 0% 0% 0% !important;
}

.form1-label {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    margin: 2% 0% 0% 0% !important;
    color: rgba(65, 65, 65, 0.85);
}

.form2-label {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

}

 .checkboxItems {
    display: flex !important;
    gap: 5px !important;
    width: 95% !important;
}

.check {
    accent-color: rgba(65, 65, 65, 0.85) !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 15px;
}

.termPriv {
    text-decoration: underline;
    font-weight: 500 !important;
    cursor: pointer;
}

.acknowledge {
    color: #414141;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, Sans-Serif;
    font-size: 19px;
    line-height: 26px;
    width: 80%;
    padding-top: 30px;
    padding-left: 12px;
}

.acknowledge span {
    color: #414141;
    font-family: 'akzidenz-grotesk-pro', Helvetica, Arial, Sans-Serif;
    font-size: 19px;
    line-height: 26px;
    color: rgba(65, 65, 65, 0.85);
} 

.buttonChoose {
    background-color: #f5f5f5 !important;
    color: rgba(65, 65, 65, 0.85) !important;
    padding: 0.5rem;
    font-family: sans-serif;
    cursor: pointer;
    text-transform: capitalize !important;
}

.buttonChoose span {
    border: 1px solid rgba(65, 65, 65, 0.85) !important;
    border-radius: 0;
}

.open-p {
    margin: 4% 0% 0% 0%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: rgba(65, 65, 65, 0.85);

}

.open-p1 {
    margin: 3% 0% 0% 0%;
    font-family: 'SD Georgia Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;
    color: #121212;

}

.check {
    color: rgba(65, 65, 65, 0.85);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 15px;
    padding: 0px;
    margin-bottom: 13px;
    border: 1px solid black !important;
    width: 16px;
}

.Open-H {
    font-family: 'SD Georgia Light';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 56px;
    letter-spacing: -0.04em;
    margin: 0% 0% 2% 0%;
    color: #414141;

}

.text1-fields input {

    outline: none;
    border-color: #f5f5ff !important;
    background-color: white !important;
    height: 35px;
    border-bottom: none;
}

.text1-fields fieldset {
    border-color: transparent;
    border-radius: 0px;
    outline: none !important;
}

.padform {
    padding-bottom: 201px;
}

.radioClick {
    padding-left: 7px;
}

.error {
    color: red;
    font-size: 1rem;
    display: flex;
    padding-top: 10px;
}

.text1-fields fieldset::after {
    border-bottom: none;
}

.text1-fields input::after {
    border-bottom: none;
}

.text1-fields input {
    outline: none;
    border-color: #f5f5ff !important;
    background-color: white !important;
    height: 35px;
    border-bottom: none;
}


@media (min-width: 320px) and (max-width: 550px) {
    .text1-fields {
        width: 325px;
        margin: 2% 0% 0% 0% !important;
    }

    .padform {
        padding-left: 43px;
    }

    .acknowledge {
        padding-top: 0px !important;
    }

.padform {
    padding-bottom: 65px;
    padding-top: 18px;
}

}

.uploadFiles {
    color: #008000;
}