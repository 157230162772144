.interviewvideo_container {
  max-width: 445px;
  background-color: black;
}

.interviewvideo_aisevices {
  height: 140px;
}

.banner_aiservices {
  max-width: 100%;
  margin-top: 3rem;
}

.title_conatiner {
  text-align: center;
  padding-top: 6rem;
  margin-bottom: 5rem !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-top: 8rem !important;
}

.title {
  color: #ffffff;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 50px !important;
  font-weight: 550 !important;
}

.subtitle {
  color: #eae6e6;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 35px;
}

.banner_video_container {
  position: absolute;
  color: #ffffff;
  font-weight: 500;
  left: 32%;
  top: 35px;
}

.banner_video_header {
  font-family: "goudy-old-style", Georgia, Serif;
}

.banner_video_button {
  border: 1px solid white !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 10px !important;
  margin-left: 120px !important;

  &:hover {
    border: 1px solid white !important;
    color: white !important;
    background: #eeeeee44 !important;
  }
}

.title_button {
  border: 1px solid white !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  margin-top: 20px !important;

  &:hover {
    border: 1px solid white !important;
    color: white !important;
    background: #eeeeee44 !important;
  }
}

.feature_interview_container {
  text-align: center;
  padding-top: 6rem;
  margin-bottom: 1.5rem !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.feature_interview_title {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-family: "goudy-old-style", Georgia, Serif;
}

.feature_interview_video {
  text-align: center;
  display: flex;
  gap: 30px;
  padding: 20px;
}

.interviewvideo_videocard {
  background-color: black;
  color: #ffffff;
  text-align: left;
}

.banner_container {
  text-align: center;
  display: flex;
  position: relative;
}

.banner_video_container1 {
  text-align: center;
  display: flex;
  margin-bottom: 8rem !important;
  margin-top: 4rem!important;
}

.aiservices_imgsub_head {
  font-weight: 400;
  color: #fff;
  font-size: 23px;
  line-height: 27px;
  font-family: "goudy-old-style", Georgia, Serif;
  padding-top: 10px
}

.img-hover-zoom {
  width: 95% !important;
  height: 100% !important;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}


#imghover {
  transition: all 1.5s ease;
}

#imghover:hover {
  transform: scale(1.05) !important;
}

.aiservices-content {
  max-width: 700px;
  margin-left: 25%;
  margin-top: 4%;

  font-family: "goudy-old-style", Georgia, Serif, sans-serif;
  font-weight: 400;

}

.bot-pad {
  padding-bottom: 72px;
}

.aiservices-content1 {
  height: 450px !important;
  cursor: pointer;
  height: 100% !important;
}

.img-sub-h {
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  padding-top: 10px;
  font-family: "goudy-old-style", Georgia, Serif;
}

.img-res {

  height: 100%;
  width: 88%;
  padding: 1px;
}

.aiservices_containers {
  margin-right: 15%;
  margin-top: 3%;
}


@media (min-width: 810px) and (max-width: 920px) {

  .aiservices-content {
    margin-left: 10.5%;
  }
}


@media (min-width: 320px) and (max-width: 768px) {
  .img-res {
    width: 100%;


  }

  .aiservices_containers {
    margin-right: 1%;
  }

  .aiservices-content1 {
    margin-left: 37px !important;
  }

  .aiservices-content {
    margin: auto;
    padding: 7% 2% 4% 0%;
    max-width: 73%;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .aiservices-content {
    max-width: 800px;
    margin-left: 23%;
  }
}

@media (min-width: 1600px) {
  .aiservices-content {
    max-width: 700px;
    margin-left: 27%;
  }
}


@media (max-width: 480px) { 
  .title {
    font-size: 17px !important;
    margin-bottom: 20rem;
  }

  .subtitle {
    font-size: 14px;
  }

  .title_conatiner {
    padding-top: 0rem;
    margin-bottom: 1rem !important ;
  }

  .banner_video_container1{
    margin-bottom: 4rem !important;
  }
}
@media (max-width: 820px) { 
  .title {
    font-size: 37px !important;
  }

  .subtitle {
    font-size: 28px;
  }

  .title_conatiner {
    padding-top: 0rem;
    margin-bottom: 1rem !important ;
  }

  .banner_video_container1{
    margin-bottom: 4rem !important;
  }
}