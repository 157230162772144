.weStand_container{
    margin-top: 40px !important;
}

.WeStandTogether-content {
    width: 40%;
    margin-left: 4%;
    margin-top: 4%;
}

.westandtogether-h3 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 20px;
    margin: 0;
    padding-bottom: 20px;
}

.westandtogether-p {
    color: #fff;
    font-family:  "goudy-old-style", Georgia, Serif;
    font-size: 22px;
    line-height: 30px;
    padding-top: 25px;
    padding-left: 12px;
 
}

.westandtogether-p1 {
    color: #121212;
    font-family: goudy-old-style,Georgia,Serif,serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 50px;
    margin: 0;
    padding-bottom: 15px;
    padding-bottom: 3%;
    padding-left: 4%;
    background-color: #000;

}
.westandtogether-block{
    margin-top: 5%;
  
    background-color: #f5f5f5;
}

.westandtogether-imgs{
    padding: 16px;
}

.westandtogether-imgs{
    width: 100%;
  }

  .westandtogether-title{
    color: #fff!important;
   
    font-weight: 400;
    line-height: 31px;
    padding-top: 5px;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
    font-size: 20px;
  }

  .westandtogether-subtitle{
    color: #fff!important;
    font-family:  "goudy-old-style", Georgia, Serif;
    font-weight: 400;
    line-height: 31px;
    padding-top: 0;
    font-size: 19px;
  }

  .img-hover-zoom{
    overflow: hidden;
  }
  
  .img-hover-zoom img {
    transition: transform .5s ease;
  }


.css-11lq3yg-MuiGrid-root{
    padding: 0% 3% 4.6% 3% !important;
    background-color: #000;
}

@media (min-width: 320px) and (max-width: 820px){
    .westandtogether-h3 {
        line-height: 40px;
        padding-top: 42px;
    }

    .css-11lq3yg-MuiGrid-root{
        padding-bottom: 40px !important;
    }
    .WeStandTogether-content {
        width: 90%;
margin: auto;
    }
}
