.enterprise{
    margin: 6% 0% 4% 0%;
}

.enterprise-title{
    width: 40%;
    margin: -3% 0% 0% 13.8%;
}

.enterprise-title p{
    color: #fff;
    font-family: 'akzidenz-grotesk-pro',Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 30px;
    margin-left: -7px;
    margin-bottom: 5px;
}

.enterprise-H{
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 35px;
    font-weight: 400;
    letter-spacing: -1.4px;
    line-height: 50px;
    margin: -10px;
    /* padding-bottom: 20px; */
}

.enterprise-items{
    margin: 5% 0% 0% 0% ;
    width: 100%;
    display: block;
}


.img-rect {
    object-fit: cover !important;
    width: 100%;
    display: block;
    margin: auto;
    margin-bottom: -15px;
}


.Head-6 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    /* padding-bottom: 15px; */
    margin: 3% 0% 0% 17%;
}

.para1 {
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    line-height: 26px;
    width: 65%;
    margin: 5% 0% 0% 17%;
}

.para2 {
    margin: 0.5% 0% 0% 17% !important;
    color: #fff;
    font-family: "goudy-old-style", Georgia, Serif;
    font-size: 19px;
    line-height: 26px;
    width: 65%;
    color: #fff;
    /* padding-bottom: 30px; */

}

.img-enterprise {
    padding-top: 44px;
    width: 66%;
    height: auto;
    display: block;
    margin: auto;
    
    object-fit: cover;
}

.images {
    width: 407px;
    height: 266px;
}
.network-block{
    background-color: #f5f5f5;
   
    padding: 10px;
    margin-top: 40px;
    margin-bottom: -14px;
}
.images-enterprise {
  
    padding: 0% 4% 0% 4%;
    margin-bottom: 40px;
}

.Hd-5 {
    margin: 3% 0% 3% 4% !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
   
    letter-spacing: 0.02em;

    color: rgba(11, 10, 10, 0.85);
}

.p-h{
    margin: 0% 0% 0% 0% !important;
}




.img-p{
    color: #414141!important;
    font-family: akzidenz-grotesk-pro,Helvetica,Arial,Sans-Serif;
    font-size: 16px!important;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 18px;
    margin: 0;
    padding-bottom: 0;
    padding-top: 8px;
    text-align: left;
    word-break: break-all;

}

.img-h-3{
    color: #121212!important;
    font-family: goudy-old-style,Georgia,Serif,serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    padding-top: 0;
}


@media screen and (min-device-width: 370px) and (max-device-width: 768px) {
    .images {
        width: 320px !important;
        height: 266px;
    }
    .para1, .para2, .Head-6 {
        width: 70%;
        /* margin: 5% 0% 0% 0%; */
        padding-left: 0px;
        margin-top: 43px;
    }

    .enterprise-title{
        width: 60%;
        margin-top: 41px;
        margin-bottom: 37px;
    }
    .enterprise-H{
        font-size: 40px;
    }

    .enterprise{
        margin-bottom: 64px;
    }

    /* .img-rect {
        object-fit: cover !important;
        width: 100%;
        display: block;
        margin: auto;
    } */
    
   
}

@media screen and (min-device-width: 769px) and (max-device-width: 1080px) {
    .images {
        width: -webkit-fill-available !important;
        height: 266px;
    }

    .enterprise-title{
        width: 54%;
    }

    .img-enterprise{
        padding-top: 33px;
    }

    .enterprise-title{
        margin-top: -1%;
        margin-bottom: 26px;
    }

    .enterprise-items{
        margin-bottom: 33px;
    }
    /* .img-rect {
        object-fit: cover !important;
        width: 100% !important;
        display: block;
        margin: auto;
    } */
   
}