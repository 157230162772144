.invo h3 {
  color: #fff;
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif;
  font-weight: 400;
  font-size: 23px;
  line-height: 22px;
  padding-bottom: 15px;
}

.innovation{
  margin-bottom: 35px;
  margin-top: 40px;
}

.invo h4{
  color: #fff;
  font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -.2px;
  font-weight: 400;
  margin-bottom: 4px;
}
.invo h1 {
  font-size: 50px;
  line-height: 42px;
  letter-spacing: -1.4px;
  margin: 0;
  font-family: "goudy-old-style", Georgia, Serif;
  font-weight: 400;
  color: #fff;
}
.invo p {
  font-size: 19px;
  line-height: 26px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
}
.stratHead {
    width: 60% !important;
    display: block;
    margin: auto;
    margin-top: 46px;
}

.stretVedio {
    display: block;
    width: 90%;
    margin: auto;
    padding-top: 63px;
    padding-bottom: 64px;
}
.Insights {
    /* min-width: 90%; */
    max-width: 700px;
    display: block;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.twoImgGrid {
    display: flex;
    flex-direction: column !important;
    gap: 30px;
}

.twoImgGridMain {
    padding: 16px 30px 55px;
    width: 85%;
    display: block;
    margin: auto;
}

.twoImgGridMainTwo {
    width: 90%;
    display: block;
    margin: auto;
    padding: 30px 30px;
}
.twoImgGridRow {
    display: flex;
    flex-direction: row;
}

.twoImgGridRow img {
    width: 50%;
    height: 700px;
 }

 @media (min-width: 414px) and (max-width: 500px) {
 .inovimg{
  /* margin-top: -120px !important; */
 }
 .stretVedio{
  padding-top: 55px;
 }

 .twoImgGrid{
  margin-bottom: -55px !important;
 }


 }

 @media (min-width: 320px) and (max-width: 600px) {
    .twoImgGridRow {
        display: flex;
        flex-direction: column !important;
    }
    .twoImgGridRow img {
        width: 100%;
        height: 700px;
     }
     .twoImgGridMain {
     
        width: 100%;
     }

     .invo h4{
      padding-bottom: 9px;
     }
   
     .Insights{
      padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 75px;
    padding-top: 40px;
     }

     
      }


.career-block2{
    background-color: #f5f5f5;
    
    padding: 100px;
  
   
    
  }
  .career-content1 {
    height: 450px !important;
    cursor: pointer;
    height: 100% !important;
  }



  #imghover{
    transition: all 1.5s ease;
  }
  #imghover:hover {
    transform: scale(1.05) !important;
  }
  

  .img-res {
   
    height: 100%;
    width: 88%; 
    padding: 1px;
    
   
  }



  @media screen and (min-device-width: 370px) and (max-device-width: 767px) {
    .career-block2{
      background-color: #f5f5f5;
      padding: 20px;
    }
    .stretVedio {
      width: 100%;
      padding-bottom: 0px;
  }
  .twoImgGrid {
    /* display: flex;
    flex-direction: column !important;
    gap: 10px; */
    margin-top: -62px;
    margin-bottom: -40px;
    gap: 30px !important;
}

.innovation{
  margin-bottom: -25px;
}
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    .career-block2{
      padding: 50px;
    }
    .stretVedio {
      width: 100%;
  }
  /* img{
    width: 100%;
    height: fit-content;
  } */
  .Insights{
    max-width: 615px;
    padding-bottom: 22px
  }

  .twoImgGridMain{
    padding: 18px 30px 57px;
  }
  .innovation{
    margin-bottom: 22px;
  }

  .stratHead{
    width: 72% !important;
  }
  }