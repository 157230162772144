.main {
padding-bottom: 15px;
margin-top: 40px;
}

.PHeading h3{
   color: #fff;
   font-family: "goudy-old-style", Georgia, Serif;
   font-size: 23px;
   font-weight: 400;
   line-height: 30px;
   margin: 0;
   padding-top: 25px;
   padding-bottom: 15px;
 }

 .PHeading p{
   color: #fff;
   font-family: "goudy-old-style", Georgia, Serif;
   font-size: 19px;
   line-height: 26px;
 }

 .enterprise-img {
    width: 100%;
    object-fit: cover;
    border-style: none;
    display: block;
 
 }
 .enterprise-h3 {
   color: #414141;
   font-family: 'goudy-old-style',Georgia,Serif;
   font-size: 22px;
   line-height: 30px;
 } 

 .enterprise-h1 {
   color: #fff;
   font-family: "goudy-old-style", Georgia, Serif;
   font-size: 50px;
   font-weight: 400;
   letter-spacing: -1.4px;
   line-height: 45px;
   margin-left: -12px !important;
   margin: 0;
   padding-bottom: 20px;
 }

 .ElementEnterpriseFirst {
    width: 90% !important;
    justify-content: center;
    margin: auto;
    padding-bottom: 15px;
 }

 .btn-view{
   
  background-color: transparent;
  border: 1px solid #9b9b9b;
  color:#fff;
  cursor: pointer;
  font-family: akzidenz-grotesk-pro,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  line-height: 15px;
  margin-top: 15px;
  width: 110px;
  }
  
  .btn-view:hover {
  color:#f5f5f5;
  background-color: #000;
  }

  .buttonPartner {
    display: flex !important;
    width: 100%;
    justify-content: end;
   
    margin: auto;
    padding-bottom: 50px;
    padding-right: 78px;
  }

  @media (min-width: 320px) and (max-width: 768px) {
    .enterprise-h1 {
      line-height: 45px;
    
    }

    .enterprise-block{
      padding-bottom: 26px;
      margin-top: 5px;
      margin-left: 8px;
    }

    .buttonPartner{
      padding-right: 26px;
    }


        }

        @media (min-width: 810px) and (max-width: 1080px){
   .buttonPartner{
   padding-right: 0px;
   }

   .btn-view{
    margin-left: 82% ;
    }

    .enterprise-h1{
      margin-left: -21px !important;
    }
        }