.positionContainer{
    padding: 67px !important;
}

.openPosition_container{
    margin-top: 40px !important;
}

.editor{
    padding: 31px;
    background-color: #F5F5F5
}

.block{
    margin-top: 38px !important;
}

.dept-line{
 cursor:pointer
}
.dept-add{
   
    padding-right: 10px;
}
.editor-item1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.editor-item1 p{
color: #121212;
font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
font-size: 19px;
font-weight: 400;
line-height: 22px;
margin: 0;
padding-bottom: 15px;
}
.editor-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
}
.checkbox-editor{
   
    width: 20px;
    height: 21px;
    margin-right: 12px;
    border: 1px solid #414141;
    cursor:pointer;
}
.search {
    background-image: url("../../../public/assets/images/casestudies/Search icon.svg");
    background-repeat: no-repeat;
    background-position: 12px 15px;
  
  
  }

input[type="text"] 
{ border: none }

.inputsearch{
width: 45%;
margin-left: 20%;
padding: 10px 50px 15px;
outline: none;
border-top: none;
border-bottom: 1px solid whitesmoke !important;
border-right: none;
border-left: none;
}

.inputsearch:hover{
  border-bottom: 1px solid black !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.position {
    padding: 20px 70px 20px 70px !important; 
}


.position-editor{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
}
.position-editor p{
    color: #fff;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    line-height: 26px;
}

@media screen and (min-device-width: 476px) and (max-device-width: 767px){
    .position {
        padding: 20px 30px 20px 30px !important;
    }
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 475px){
    .position {
        padding: 20px 0px 20px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 768px) {
    .inputsearch{
        width: 75%;
        margin-left: 10%;
        }

        .positionContainer{
            /* margin-left: -14px !important; */
            padding-left: 84px !important;
        }

        
        }
    
.ContentDesc {
    color: #414141;
    font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    line-height: 26px;
}


.iconbutton{
    margin-right: 9px;
}
