.career-block2{
  background-color: black;
  padding: 55px 26px 0px 38px;
  
}

.img-hover-zoom{
  width: 95% !important;
  height: 100% !important;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}


#imghover{
  transition: all 1.5s ease;
}
#imghover:hover {
  transform: scale(1.05) !important;
}


.career-title {
  max-width: 700px;
  margin-left: 25%;
  padding-top: 64px;
  padding-bottom: 3%;
  font-family: "goudy-old-style",Georgia,Serif,serif;
  font-weight: 400;
  color: #414141;
  margin-top: 40px;
}
.career-title h1 {
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1.4px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 20px;
}
.career-content {
  max-width: 700px;
  margin-left: 25%;
  margin-top: 4%;
 
  font-family: "goudy-old-style",Georgia,Serif,sans-serif;
  font-weight: 400;
 
}
.bot-pad{
  padding-bottom: 72px;
}
.career-content-h {
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  margin-bottom: 19px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
}
.career-content-p {
  font-size: 19px;
  line-height: 26px;
  color: #fff;
  font-family: "goudy-old-style", Georgia, Serif;
}
.career-img {
  height: 170px !important;
  width: 100% !important ;
  
}

.career-imgText {
  font-family: "goudy-old-style",Georgia,Serif,serif;
  padding-top: 10px;
  color: #121212;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  margin-left: 5%;
}
.career-items {
  padding-left: 5%;
  
}
.career-img2 {
  width: 100%;
  height: 160px;
}
.career-sec2 {
  padding: 5% 5% 5% 5% !important;
  background-color: #f5f5f5;
}
.career-sec2-p {
  margin: 3px 0px 3px 0px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -.2px;
  color: #414141;
  font-weight: 400;
  font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
}
.career-sec2-h {
  padding: 0px;
  font-family: "goudy-old-style",Georgia,Serif,serif;
  color: #121212;
  font-size: 22px;
  line-height: 27px;
  margin: 3% 0% 0% 7.5% ;
  font-weight: 400;
}
#career-part {
  padding: 5% 5% 10% 5%;
}

.career-content1 {
  height: 450px !important;
  cursor: pointer;
  height: 100% !important;
}

.img-sub-h{
  font-weight: 400;
  color: #fff;
  font-size: 23px;
  line-height: 27px;
  padding-top: 10px;
  
  font-family: "goudy-old-style", Georgia, Serif;
}

.img-sub-head {
  font-weight: 400;
  color: #fff;
  font-size: 23px;
  line-height: 27px;
  margin: 1% 0% 20% 0%;
  font-family: "goudy-old-style", Georgia, Serif;
}

.img-res {
  
  height: 100%;
  width: 88%; 
  padding: 1px;
  
 
}

.search {
  background-image: url("../../../public/assets/images/careers/Search icon.svg");
  background-repeat: no-repeat;
  background-position: 12px 15px;
  font-family: "goudy-old-style",Georgia,Serif,serif;

}

input[type="text"] 
{ border: none  }

.inputsearch{
  font-family: "goudy-old-style",Georgia,Serif,serif;
  width: 40%;
  margin-left: 21%;
padding: 11px 40px 10px;
outline: none;
border-top: none;
border-bottom: 1px solid whitesmoke !important;
border-right: none;
border-left: none;
}

.inputsearch:hover{
  border-bottom: 1px solid black !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.input-career-icon{
  margin-top: 2%;
 
}


@media (min-width: 810px) and (max-width: 920px){
  .career-block2{
    padding: 30px 20px 52px 56px;
  }

  .career-title{
    padding-top: 52px;
  }

 

  .career-imgs{
    margin-left: 0px !important;
  }

  .career-content {
    margin-left: 10.5%;
  }
  .img-sub-head{
    margin-bottom: 8px;
  }
  .career-content-p{
    max-width: 645px;
  }
}


@media (min-width: 320px) and (max-width: 768px) {

  .career-block2{
    padding: 22px 20px 42px 0px;
    margin-top: 8px !important;
  }

  .img-sub-head {
    margin: 1% 0% 5% 0%;
  }


  .inputsearch{
  width: 75%;
      margin-left: 0;
      padding: 10px 40px 10px 42px;
  }
  .img-res {
    width: 100%; 
    
   
  }

  .career-content1{
    margin-left: 37px !important;
}
  
  /* .input-career-icon{
    display: flex;
    justify-content: center;
  } */

  .career-content {
    margin: auto;
    padding: 7% 2% 4% 0%;
    max-width: 73%;
  }
  .career-title {
    margin-left: 9.5%;
    /* text-align: center; */
    max-width: none;
    padding-top: 10%;
  }
  .career-title h1{
    font-size: 50px;
    line-height: 42px;
    letter-spacing: -.2px;
    color: #fff;
    font-family: "goudy-old-style",Georgia,Serif,serif;
  }
  .career-content-h{
    font-size: 18px;
    line-height: 26px;
    font-family: "goudy-old-style",Georgia,Serif,serif;
    color: #ffffff;
  }
  .career-content-p{
    font-size: 16px;
    line-height: 26px;
    font-family: "goudy-old-style",Georgia,Serif;
  }
  .career-imgText{
    font-size: 22px;
   
    color: #121212;
    line-height: 27px;
    font-family: "goudy-old-style",Georgia,Serif,serif;
  }
  .career-sec2-p{
    color: #414141;
    font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -.2px;
  }
  .career-sec2-h{
    font-family: "goudy-old-style",Georgia,Serif,serif;
    font-size: 22px;
 
    line-height: 27px;
  }
  .career-items {
    padding-left: 0%;
  }
  .career-img {
    height: 220px !important;
  }
  .career-img2 {
    height: 230px;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .career-content {
    max-width: 800px;
    margin-left: 23%;
  }
  .career-title {
    max-width: 800px;
    margin-left: 23%;
  }
  .career-img2 {
    height: 185px;
  }
  .career-sec2 {
    padding: 5% 13% 5% 13% !important;
  }
}
@media (min-width: 1600px) {
  .career-content {
    max-width: 700px;
    margin-left: 27%;
  }
  .career-title {
    max-width: 700px;
    margin-left: 27%;
  }
  .career-img2 {
    height: 217px;
  }
  .career-sec2 {
    padding: 5% 16% 5% 16% !important;
  }
}
