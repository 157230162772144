.dt{
    margin: 6% 0% 0% 0%;
}

.datatemplate_container{
    margin-top: 40px !important;
}

.dt-title{
    width: 40%;
    margin: -3.5% 0% 3% 13%;
}

.dt-title-p{
    color: #fff;
    font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 3px;
    letter-spacing: -.2px;
    font-weight: 400;
}

.itlaicFont {
    color: #414141;
    font-family: "akzidenz-grotesk-pro",Helvetica,Arial,Sans-Serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -.2px;
    font-weight: 400;
}

.dt-heading{
    font-size: 50px;
    line-height: 37px;
    letter-spacing: -1.4px;
    margin: 0;
    font-family: "goudy-old-style", Georgia, Serif;
    font-weight: 400;
    color: #fff;
    padding-bottom: 23px;
     
}
.dt-future-img{
    width: 100%;
    object-fit: cover !important;
}

.dt-items{
margin-left: 6%;
width: 68% ;
}

.dt-items-p{
   
    margin: 0% 0% 0% 18%;
    font-family: "goudy-old-style", Georgia, Serif;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    padding: 5% 0% 0% 5%;
    color: #fff;
    
}


.dt-items-p1{
   
    margin: 0% 0% 0% 18%;
    font-family: "goudy-old-style", Georgia, Serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 32px;
    padding: 2% 0% 0% 5%;
    color: #fff;
    
}


.dt-items-p2{
   
    margin: 0% 0% 0% 18%;
    font-family: "goudy-old-style",Georgia,Serif,serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 32px;
    padding: 1% 0% 0% 5%;
    color: #fff;
    
}



.dt-h6{
    font-size: 23px;
    font-family: "goudy-old-style", Georgia, Serif;
    color: #fff;
    margin: 3% 0%  -1% 23%;
    font-weight: 300;
}

.dt-ul{
    margin: 3% 0% 2% 23%;
    padding: 0% 0% 0% 0%;
    font-size: 19px;
    font-weight: 300;  
    line-height: 32px;
    font-family: "goudy-old-style", Georgia, Serif;
}

.dt-ul>li{

    margin: 0% 0% 2% 0%;
    font-size: 19px;
    font-weight: 300;  
    font-family: "goudy-old-style",Georgia,Serif,serif;
    
}


.discover-btn{
    padding-top: 30px;
    padding-bottom: 75px;
}

.discover-btn button{

        margin-top: 15px;
        width: 205px;
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        border: 1px solid #9B9B9B;
        color: #fff;
        cursor: pointer;
        background-color: transparent;
        border-radius: 0;
    
}
.discover-btn button:hover{
    color:#f5f5f5;
    background-color: #000;
    outline: none;
}


@media (min-width: 320px) and (max-width: 768px) {

    .dt-heading{
        padding-bottom: 45px;
        line-height: 41px;
        padding-top: 6px;
    }
    .dt-h6{
        margin: 3% 0% -1% 18%;
    }

    .dt-future-img{
        width: 100%;
        object-fit: cover !important;
    }
    

    .dt-ul{
        margin: 3% 0% 2% 18%;
    }
    .dt-items-p1{
        padding: 2% 0% 0% 1%;
    }

    .dt-title{
        width: 60%;
        margin: 8.4% 0% 3% 13%;
    }

    .dt-items{
        margin-left: 0%;
        width: 90% ;
        margin-top: 40px;
        }
     .dt-items-p {
        padding: 0;
     }

}

@media (min-width: 769px) and (max-width: 980px){
    .dt-title{
        width: 50%;
        margin-top: -18px;
        margin-bottom: 38px;
    }

    .dt-title-p{
        margin-bottom: 1px;
    }

    .discover-btn{
        padding-bottom: 82px;
    }

}