.blog-title {
  max-width: 700px;
  margin-left: 22%;
  padding-top: 32px;
  padding-bottom: 1%;
}

.blog_container{
  margin-top: 40px;
}
.aiblog-H {
  padding-left: 10px;
  margin-left: 4.5%;
  /* margin-top: 60px */
  padding-top: 74px;
  font-size: 23px;
  padding-bottom: 28px;
  font-family: "goudy-old-style", Georgia, Serif;
  color: #fff;
 
}
.blog-heading {
  font-family: "goudy-old-style", Georgia, Serif;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 56px;
  letter-spacing: -0.04em;
  color: #fff;

}

.blog-content {
  max-width: 700px;
  margin-left: 22%;
  margin-top: 1%;
}
.CloseButton {
  position: relative;

  top: 10px;
  right: 30px;
  cursor:pointer;
}

@media (min-width: 320px) and (max-width: 768px) {
  .aiblog-H {
    padding-top: 30px !important;
    padding-bottom: 57px;
    margin-left: 3.5%;
    color: #fff;
}
  .blog-contentp {
    padding-right: 10px !important;
  }
  .inputsearch {
    width: 95% !important;
    }
.blog-content{
  margin-left: 5%;
}
.CloseButton {
  position: relative;

top: -37px;
left: 86%;

cursor: pointer;
}

.blog-title{
  margin-left: 5%;
}


}

.blog-contentp {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  
  color: #121212;

}

.blog-part {
  width: 100%;
  padding: 5% 2.8% 5% 2.8%;
}

.blog-imghover {
  width: 100%;
}

.blogsubtitle {
  font-weight: 400;
  color: #fff !important;
  font-size: 25px;
  line-height: 31px;
  padding-top: 0px;
 
  font-family: "goudy-old-style", Georgia, Serif;
}


.blogtitle {
  text-align: left;
  margin-top: 2px;
  padding-top: 8px;
  padding-bottom: 0px;
  
  font-size: 16px !important;
  font-weight: 400;
  color: #fff !important;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin: 0px;
  font-family: "akzidenz-grotesk-pro", Helvetica, Arial, Sans-Serif;
  word-break: break-all;
}



.blog-img {
  margin-top: 0%;
  margin-left: 1%;
}

.blog-imgs {
 
  padding: 16px;
}



.img-hover-zoom{
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

#imghover {
  transition: all 1.5s ease;
}

#imghover:hover {
  transform: scale(1.05) !important;
}

.search {
  background-image: url("../../../public/assets/images/casestudies/Search icon.svg");
  background-repeat: no-repeat;
  background-position: 12px 15px;
}

input[type="text"] 
{ border: none }

.inpWidth{
width: 100% !important ;
  }
  
  .inputsearch:hover{
    border-bottom: 1px solid black !important;
  }

  input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
  
@media (min-width: 320px) and (max-width: 768px) {
.blog-part {
  /* padding: 5% 2.8% 5% 2.2%; */
  padding-left: 24px;
  padding-bottom: 37px;
}
.blog-img{
  margin-top: 4.5%;
}
.blog-img img {

  width: 100%;
}
.inpWidth{
  margin-left: -22px;
  width: 91% !important;
}
.blog-title{
  padding-top: 34px;
}
}
@media (min-width: 475px) and (max-width: 850px) {
  .inpWidth{
    width: 70% !important;
    margin-left: -192px !important;
      }

      .blog-part {
        padding-top: 5.5%;
        padding-left: 30px;
      }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .aiblog-H {
      margin-left: 2.3%;
      padding-bottom: 35px;
  }
  }